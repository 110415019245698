const env = process.env.REACT_APP_ENV;

let apiUrl;
let imageUrl = process.env.REACT_APP_IMAGE_URL;

let baseUrlSocial = "";
let baseUrlUser = "";
let baseUrlCms = "";
let baseUrlAcademics = "";
let baseUrlChatfolder = "";


// Centralized API URL configuration based on environment
const setApiUrls = () => {
  switch (env) {
    case "testing":
      apiUrl = process.env.REACT_APP_GROWTH_API_TESTING;
      baseUrlSocial = apiUrl
      baseUrlUser = apiUrl
      baseUrlCms = apiUrl
      baseUrlAcademics = apiUrl
      baseUrlChatfolder = apiUrl
      break;
    case "staging":
      apiUrl = process.env.REACT_APP_GROWTH_API_STAGING;
      baseUrlSocial = apiUrl
      baseUrlUser = apiUrl
      baseUrlCms = apiUrl
      baseUrlAcademics = apiUrl
      baseUrlChatfolder = apiUrl
      break;
    case "production":
      console.log('production');
      baseUrlSocial = process.env.REACT_APP_PROD_SOCIAL;
      baseUrlUser = process.env.REACT_APP_PROD_USER
      baseUrlCms = process.env.REACT_APP_PROD_CMS
      baseUrlAcademics = process.env.REACT_APP_PROD_ACADEMIC
      baseUrlChatfolder = process.env.REACT_APP_PROD_CHAT
      return;
    default:
      console.error("Unknown environment:", env);
      return;
  }

};

// Call the function to set the API URLs
setApiUrls();

export const api = apiUrl;
export const image_url = imageUrl;
export const academics = "academics/";
export const user = "user/";
export const chatfolder = "chatfolder/";
export const social = "social/";

export const academicexporturl = baseUrlAcademics + academics;

// Function to refresh auth token for dashboard
export const refreshAuthTD = async () => {
  const refreshTokenUrl = process.env.REACT_APP_REFRESH_TOKEN_URL;
  try {
    const response = await fetch(refreshTokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    });

    if (!response.ok) throw new Error('Failed to refresh token');

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

// Function to fetch job upload status with token management
export const fetchJobUploadStatus = async () => {
  const access_token = JSON.parse(localStorage.getItem("logedin-user"))?.[0]?.access_token;
  const azurADToken = access_token?.azurADToken || "";
  const endpoint = "academics/v2/content-upload-status";
  const fullApiUrl = `${baseUrlAcademics}${endpoint}`;
  try {
    let responseData = await makeApiRequest(fullApiUrl, "GET", {
      'Authorization': `Bearer ${azurADToken}`,
      'Cache-Control': 'no-cache',
    });

    // Check if token is invalid and refresh the token if needed
    if (responseData?.message === "Token Not Valide!") {
      const newToken = await refreshAuthToken();
      if (newToken) {
        responseData = await makeApiRequest(fullApiUrl, "GET", {
          'Authorization': `Bearer ${newToken}`,
        });
      } else {
        console.error("Failed to refresh token");
        return null; // Return null if token refresh failed
      }
    }

    return responseData; // Return the valid response

  } catch (error) {
    console.error('Error fetching job upload status:', error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};

// Function to set tenant ID (call this when you retrieve it)
const setTenantId = (id) => {
  localStorage.setItem("tenantId", id);
};



// Function to fetch dashboard data
export const fetchDashboardData = async (token,selectedYear) => {
  const endpoint = "social/v2/dashboard/";
  let tenantId = localStorage.getItem("tenantId") || 1;
  let setYear="?year=";
  // If tenantId is not found, you might want to fetch it from the server or handle it
  if (!tenantId || tenantId === 'null') {
    console.error("Invalid tenantId: will setting it to 1", tenantId);

    throw new Error("Invalid tenant ID. Please ensure you are logged in and try again.");
  }

  const fullApiUrl = `${baseUrlSocial}${endpoint}${tenantId}${setYear}${selectedYear}`;

  try {
    const response = await fetch(fullApiUrl, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to fetch user data: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};


// Function to handle API requests
const makeApiRequest = async (url, method, headers, body) => {
  const response = await fetch(url, { method, headers, body });
  if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
  return response.json();
};

// Function to fetch data with token management
export const fetchData = async (url, payload, service) => {
  try {
    const access_token = JSON.parse(localStorage.getItem("logedin-user"))?.[0]?.access_token;
    const azurADToken = access_token?.azurADToken || "";
    const api_url = getApiUrl(service);

    let responseData = await makeApiRequest(api_url + url, "POST", {
      "Content-Type": "application/json",
      Authorization: `Bearer ${azurADToken}`,
    }, JSON.stringify(payload));

    if (responseData?.message === "Token Not Valide!") {

      const newToken = await refreshAuthToken();
      if (newToken) {
        responseData = await makeApiRequest(api_url + url, "POST", {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newToken}`,
        }, JSON.stringify(payload));
      } else {
        return null;
      }
    }

    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Function to get API URL based on service
const getApiUrl = (service) => {
  switch (service) {
    case academics: return baseUrlAcademics + service;
    case user: return baseUrlUser + service;
    case chatfolder: return baseUrlChatfolder + service;
    case social: return baseUrlSocial + service;
    default: return baseUrlCms + "cms/";
  }
};

// Function to handle refresh token logic
export const refreshAuthToken = async () => {
  try {
    const refreshTokenResponse = await fetch(process.env.REACT_APP_REFRESH_TOKEN_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const refreshTokenData = await refreshTokenResponse.json();

    if (refreshTokenData?.code === 1) {
      const newToken = refreshTokenData.data;
      const logedinUser = JSON.parse(localStorage.getItem("logedin-user")) || [];
      logedinUser[0].access_token.azurADToken = newToken;
      localStorage.setItem("logedin-user", JSON.stringify(logedinUser));
      return newToken;
    } else {

      localStorage.removeItem("logedin-user");
      localStorage.removeItem("tenantId");
      return null;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

export const dropdownData = async (
  responseData,
  fieldName,
  type,
  master_name
) => {
  try {
    var options = [];
    responseData.forEach((element) => {
      if (type == "user_access") {
        options.push({
          label: element[fieldName],
          value: element.id,
          type: element.type,
        });
      } else if (type == "join") {
        options.push({
          label: element[master_name][fieldName],
          value: element[master_name].id,
        });
      } else {
        options.push({ label: element[fieldName], value: element.id });
      }
    });
    const dropdownResponseData = [
      {
        options: options,
      },
    ];
    return dropdownResponseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately (throw error, display error message, etc.)
    throw error; // Re-throw the error for further handling if needed
  }
};

export const fileImport = async (url, formData, service) => {
  let access_token = JSON.parse(localStorage.getItem("logedin-user")) ? JSON.parse(localStorage.getItem("logedin-user"))[0].access_token : null;
  let azurADToken = access_token && access_token.azurADToken ? access_token.azurADToken : "";

  let api_url;
  if (service == academics) {
    api_url = baseUrlAcademics + service;
  } else if (service == user) {
    api_url = baseUrlUser + service;
  } else if (service == chatfolder) {
    api_url = baseUrlChatfolder + service;
  } else if (service == social) {
    api_url = baseUrlSocial + service;
  } else {
    api_url = baseUrlCms + "cms/";
  }
  const makeApiRequestForFileImport = async (azurADToken) => {
    const response = await fetch(api_url + url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${azurADToken}`,
      },
      body: formData,
    });
    return response.json();
  };

  let responseData = await makeApiRequestForFileImport(azurADToken);
  if (responseData && responseData.message === "Token Not Valide!") {


    // Refresh token and get the new one
    const newToken = await refreshAuthToken();

    if (newToken) {
      // Retry the file import with the new token
      responseData = await makeApiRequestForFileImport(newToken);
    } else {
      return null; // Exit if token refresh failed
    }
  }

  return responseData.code;
};

export const downloadBlobData = async (service, lastSegment) => {
  const downloadurl = "downloadMediaUrl/" + lastSegment;
  let api_url;

  // Set the appropriate base URL based on the service
  if (service == academics) {
    api_url = baseUrlAcademics + service;
  } else if (service == user) {
    api_url = baseUrlUser + service;
  } else if (service == chatfolder) {
    api_url = baseUrlChatfolder + service;
  } else if (service == social) {
    api_url = baseUrlSocial + service;
  } else {
    api_url = baseUrlCms + "cms/";
  }

  // Fetch the blob data and download the file
  const response = await fetch(api_url + downloadurl);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = lastSegment; // Optional file name
  document.body.appendChild(a);
  a.click();
  a.remove();
};

