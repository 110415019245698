import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Import i18n
import { withTranslation } from "react-i18next";

// Import Megamenu
import MegaMenu from "./MegaMenu";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

// Import logo Images
import logo from "../../assets/images/LLCLoginLogo.svg";

// Import Social Profile Images
import { fetchData } from "../../services/fetchData";
import { useDispatch } from "react-redux";
import { fetchJobStatus } from "../../store/actions";
const Header = ({ toggleMenuCallback, toggleRightSidebar }) => {
  const [data, setData] = useState([]);
  const dispatch=useDispatch();
  const globalState = useSelector((state) => state.global);
  const activeJob = globalState.activeJob
  const uploadType=globalState.uploadType;
  const errorType=globalState.error;

  useEffect(() => {
    const getData = async () => {
      try {
        let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
        let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
        authID = JSON.parse(authID);
        authID = authID[0];

        let payload = {
          modelName: "tenant_masters",
          whereCondition: {
            is_active: 1,
          },
        };

        if (authID.type === 1 && tenantId) {
          // admin
          payload.whereCondition.id = tenantId;
        }
        if (authID.type === 2) {
          // tenant id
          payload.whereCondition.id = authID.tenant_id;
        }

        const responseData = await fetchData("getMasterList", payload);

        if (responseData.code === 1) {
          responseData.data.forEach((element, index) => {
            element.index = index + 1;
          });
          responseData.data = responseData.data.sort((a, b) => b.index - a.index);
          setData(responseData.data); 
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []); 

  useEffect(()=>{
   dispatch(fetchJobStatus());
  }, [dispatch]);




  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-light">
                <span className="logo-lg">
                  <img
                    src={logo}
                    alt=""
                    style={{
                      transform: "scale(0.9)",
                      marginLeft: "-10px",
                      marginTop: "10px",
                    }}
                  />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
             
              type="button"
              onClick={toggleMenuCallback}
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              style={{
                backgroundColor:"white"
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>

            <MegaMenu />
          </div>
          {/* Spinner on topbar */}
          {activeJob === false && uploadType === 1 && errorType!=null && (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "10px 15px",
      backgroundColor: "#fce8e6",
      color: "#d93025",
      border: "1px solid #f28b82",
      borderRadius: "8px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
    }}
  >
    <i
      className="ri-error-warning-line"
      style={{ fontSize: "0.9rem", color: "#d93025" }}
    ></i>
    <span style={{ fontSize: "0.9rem", fontWeight: "500" }}>
      Academic Upload Failed
    </span>
  </div>
)}

{activeJob === false && uploadType === 2 && errorType!=null  && (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "10px 20px",
      backgroundColor: "#fce8e6",
      color: "#d93025",
      border: "1px solid #f28b82",
      borderRadius: "8px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
    }}
  >
    <i
      className="ri-error-warning-line"
      style={{ fontSize: "0.9rem", color: "#d93025" }}
    ></i>
    <span style={{ fontSize: "0.9rem", fontWeight: "500" }}>
      Content Upload Failed
    </span>
  </div>
)}

{activeJob === true  && (
  <div
    id="spinner"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      backgroundColor: "#e8f0fe",
      color: "#1a73e8",
      border: "1px solid #d2e3fc",
      borderRadius: "8px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
      gap: "10px",
    }}
  >
    <div
      className="spinner-border text-primary"
      role="status"
      style={{
        width: "1.2rem",
        height: "1.2rem",
        borderWidth: "2px",
      }}
    ></div>
    <span style={{ fontSize: "0.9rem", fontWeight: "500" }}>
      Upload in Progress (
      <strong>{uploadType === 1 ? "Academic" : "Content"}</strong>)
    </span>
  </div>
)}
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
);
