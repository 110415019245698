import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Label, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { apiError } from "../../store/actions";
import { fetchData, user } from "../../services/fetchData";
import { toast } from "react-toastify";
import logo from "../../assets/images/LLCLoginLogo.svg";
import { authLogin } from "../../services/endPoints"; 

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginError = useSelector((state) => state.Login.loginError);
  

  useEffect(() => {
    dispatch(apiError(""));
    document.body.classList.add("auth-body-bg");

    const loggedInUser = localStorage.getItem("logedin-user");
    if (loggedInUser) {
      navigate("/");
    }

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, [dispatch, navigate]);

  const findFirstAccessibleMenu = (allMenus, accessMenuIds) => {
    // Get parent menus sorted by sequence
    const parentMenus = allMenus
      .filter((menu) => !menu.parent_id || menu.parent_id === 0)
      .sort((a, b) => a.sequence - b.sequence);

    // Find first accessible child menu
    for (const parentMenu of parentMenus) {
      const accessibleChildren = allMenus
        .filter(
          (menu) =>
            menu.parent_id === parentMenu.id &&
            accessMenuIds.includes(menu.id)
        )
        .sort((a, b) => a.sequence - b.sequence);

      if (accessibleChildren.length > 0) {
        return accessibleChildren[0].menu_url;
      }
    }
    return null;
  };

  const handleSubmit = async (event, values) => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);

      // Validate inputs
      if (!values.username?.trim() || !values.password?.trim()) {
        toast.warning("All fields are required!");
        return;
      }

      // Additional validation for username and password length
      if (values.username.length < 3 || values.password.length < 6) {
        toast.warning("Username must be at least 3 characters and password at least 6 characters.");
        return;
      }

      const loginPayload = {
        modelName: "cms_user_masters",
        username: values.username.trim(),
        password: values.password.trim(),
      };

      const loginResponse = await fetchData(authLogin.LOGIN, loginPayload, user);

      if (!loginResponse?.status === 201 || !loginResponse?.code === 1) {
        throw new Error("Invalid username or password. Please try again.");
      }

      if (!loginResponse.data?.length) {
        throw new Error("Invalid response data");
      }

      // Store user data without password
      const userData = loginResponse.data.map(({ password, ...rest }) => rest);
      localStorage.setItem("logedin-user", JSON.stringify(userData));

      // Fetch menu and access data
      const [menuResponse, accessResponse] = await Promise.all([
        fetchData(authLogin.GET_MASTER_LIST, {
          modelName: "menu_masters",
          whereCondition: { is_active: 1 },
        }),
        fetchData(authLogin.GET_MASTER_LIST, {
          modelName: "access_controls",
          whereCondition: {
            user_id: userData[0].id,
            access: 1,
            is_active: 1,
          },
          pagination: { page: 1, pageSize: 100 },
        }, user),
      ]);

      if (!menuResponse?.code === 1 || !accessResponse?.code === 1) {
        throw new Error("Failed to fetch menu data");
      }

      const accessMenuIds = accessResponse.data.map((item) => item.menu_id);
      const redirectUrl = findFirstAccessibleMenu(menuResponse.data, accessMenuIds);

      if (!redirectUrl) {
        throw new Error("No accessible menu items found");
      }

      toast.success(`${userData[0].first_name} ${loginResponse.message}`);
      navigate(redirectUrl);

    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.message || "An unexpected error occurred. Please try again later.");
      dispatch(apiError(error.message));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logo}
                                alt=""
                                height="60"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logo}
                                alt=""
                                height="60"
                                
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>
                        </div>
                        {loginError && <Alert color="danger">{loginError}</Alert>}
                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Username</Label>
                              <AvField
                                name="username"
                                value={username}
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                onChange={(e) => setUsername(e.target.value)}
                                validate={{
                                  required: { value: true, errorMessage: "Username is required" },
                                  minLength: { value: 3, errorMessage: "Username must be at least 3 characters" },
                                  maxLength: { value: 50, errorMessage: "Username cannot exceed 50 characters" },
                                }}
                              />
                            </div>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                validate={{
                                  required: { value: true, errorMessage: "Password is required" },
                                  minLength: { value: 6, errorMessage: "Password must be at least 6 characters" },
                                }}
                              />
                            </div>
                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Logging in..." : "Log In"}
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
