import React, { useState, useMemo, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';

// Styled components
const CardWrapper = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: ${props => props.$windowWidth < 576 ? '10px' : '20px'};
    margin-bottom: 20px;
    height: ${props => {
        if (props.$windowWidth < 576) return '320px';
        if (props.$windowWidth < 768) return '400px';
        if (props.$windowWidth < 992) return '418px';
        return '418px';
    }};
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.$windowWidth < 576 ? '20px' : '40px'};
    flex-wrap: wrap;
    padding: ${props => props.$windowWidth < 576 ? '0 10px' : '0 15px'};
`;

const HeaderTitle = styled.h2`
    margin: 0;
    font-size: ${props => {
        if (props.$windowWidth < 576) return '12px';
        if (props.$windowWidth < 768) return '15px';
        if (props.$windowWidth < 992) return '16px';
        return '16px';
    }};
    color: #333;
    font-weight: 600;
`;
const MonthDisplay = styled.div`
    text-align: center;
    font-size: ${props => props.$windowWidth < 576 ? '12px' : '15px'};
    height: 20px;
    overflow: hidden;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: ${props => props.$windowWidth < 576 ? '6px' : '11px'};
    gap: 3px;
    

`;

const StyledButton = styled.button`
    flex: 1;
    min-width: ${props => props.$windowWidth < 576 ? '60px' : '70px'};
    max-width: ${props => props.$windowWidth < 576 ? '80px' : '90px'};
    padding: ${props => props.$windowWidth < 576 ? '5px 0' : '7px 0'};
    font-size: ${props => props.$windowWidth < 576 ? '12px' : '13px'};
    background-color: ${props => props.$isSelected ? '#5664D2' : '#F8F9FA'};
    color: ${props => props.$isSelected ? 'white' : 'black'};
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 2px;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${props => props.$isSelected ? '#5664D2' : '#e9ecef'};
    }
`;

const ChartContainer = styled.div`
    height: ${props => props.$windowWidth < 576 ? '180px' : '270px'};
    margin-bottom: ${props => props.$windowWidth < 576 ? '15px' : '20px'};
    
`;

const SkeletonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: ${props => props.$windowWidth < 576 ? '15px' : '20px'};
`;

const SkeletonButtonGroup = styled(ButtonGroup)`
    display: flex;
    gap: 10px;
    margin-top: 15px;
`;
const PlaceholderMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: #888;
    margin-top: 150px;
`;

const JoinedUsers = ({ graphData,loading }) => {
    const [timeFrame, setTimeFrame] = useState("monthly");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const timeFrames = ["weekly", "monthly", "yearly"];

    useEffect(() => {
        if (timeFrame === "monthly") {
            const currentMonth = new Date().getMonth();
            setSelectedMonth(months[currentMonth]);
        }
    }, [timeFrame, months]);

    // Debounced resize handler
    useEffect(() => {
        const handleResize = debounce(() => {
            setWindowWidth(window.innerWidth);
        }, 150);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, []);

    const graphDataMemo = useMemo(() => {
        if (!graphData) return [];
        switch (timeFrame) {
            case "weekly":
                return graphData.weeklyJoiningData?.map(item => item.count) || [];
            case "monthly":
                return graphData.monthlyJoiningData?.map(item => item.count) || [];
            case "yearly":
                return graphData.yearlyJoiningData?.map(item => item.count) || [];
            default:
                return [];
        }
    }, [graphData, timeFrame]);

    const labelsMemo = useMemo(() => {
        if (!graphData) return [];
        switch (timeFrame) {
            case "weekly":
                return graphData.weeklyJoiningData?.map(item => item.date) || [];
            case "monthly":
                return graphData.monthlyJoiningData?.map(item => new Date(item.date).getDate()) || [];
            case "yearly":
                return months;
            default:
                return [];
        }
    }, [graphData, timeFrame, months]);

    const chartOptions = useMemo(() => ({
        chart: {
            type: 'bar',
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: false
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        plotOptions: { bar: { horizontal: false, columnWidth: '20%' } },
        dataLabels: { enabled: false },
        legend: { show: false },
        colors: ['#1cbb8c'],
        xaxis: {
            categories: labelsMemo,
            tickPlacement: 'on',
            labels: {
               
                show:windowWidth < 576 ? false : true,
                style: { fontSize: '12px' },
                rotate: -45,
                formatter: (value) => (timeFrame === "monthly" && windowWidth < 600 ? ' ' : value),
            }
        },
        yaxis: {
            
        },
    }), [labelsMemo, graphDataMemo, timeFrame, windowWidth]);

    // Loading state render
    if (loading) {
        return (
            <CardWrapper $windowWidth={windowWidth}>
                <SkeletonContainer $windowWidth={windowWidth}>
                    <Skeleton 
                        height={30} 
                        width="100%" 
                        style={{ marginBottom: '15px' }} 
                    />
                    <Skeleton 
                        height={20} 
                        width="100%" 
                        style={{ marginBottom: '10px' }} 
                    />
                    <SkeletonButtonGroup $windowWidth={windowWidth}>
                        <Skeleton height={30} width="100%" />
                        <Skeleton height={30} width="100%" />
                        <Skeleton height={30} width="100%" />
                    </SkeletonButtonGroup>
                    <Skeleton 
                        height={170} 
                        width="100%" 
                        style={{ marginTop: '15px' }} 
                    />
                </SkeletonContainer>
            </CardWrapper>
        );
    }

    // Main render
    return (
        <CardWrapper $windowWidth={windowWidth}>
            <HeaderContainer $windowWidth={windowWidth}>
                <HeaderTitle $windowWidth={windowWidth}>
                    Joined User Analytics
                </HeaderTitle>
                {timeFrame === "monthly" && (
                            <MonthDisplay>
                                {selectedMonth}
                            </MonthDisplay>
                        )}
                        {timeFrame === "yearly" && (
                            <MonthDisplay>
                                {new Date().getFullYear()}
                            </MonthDisplay>
                        )}
                <ButtonGroup $windowWidth={windowWidth}>
                    {timeFrames.map(frame => (
                        <StyledButton
                            key={frame}
                            $windowWidth={windowWidth}
                            $isSelected={timeFrame === frame}
                            onClick={() => setTimeFrame(frame)}
                        >
                            {frame.charAt(0).toUpperCase() + frame.slice(1)}
                        </StyledButton>
                    ))}
                </ButtonGroup>
            </HeaderContainer>
            <ChartContainer $windowWidth={windowWidth}>
                {Object.keys(graphDataMemo ?? {}).length === 0  ? (
                    <PlaceholderMessage>No data available for the selected timeframe.</PlaceholderMessage>
                ) : (
                    <Chart
                    options={chartOptions}
                    series={[{ name: 'New Users Joined', data: graphDataMemo }]}
                    type="bar"
                    height="100%"
                />
                )}
            </ChartContainer>
        </CardWrapper>
    );
};

JoinedUsers.propTypes = {
    graphData: PropTypes.shape({
        weeklyJoiningData: PropTypes.array,
        monthlyJoiningData: PropTypes.array,
        yearlyJoiningData: PropTypes.array,
    }),
};

export default JoinedUsers;
