import { takeLatest, call, put, delay, select } from 'redux-saga/effects';
import {
  FETCH_JOB_STATUS,
  STOP_POLLING,
  SET_GLOBAL_ACTIVE_JOB,
  SET_GLOBAL_UPLOAD_TYPE,
  SET_UPLOAD_ERROR,
  SET_ERROR,
  START_POLLING,
} from './actionTypes';
import { fetchJobUploadStatus } from '../../services/fetchData'; // Ensure this is correct

function* fetchJobStatusSaga() {
  try {
    const response = yield call(fetchJobUploadStatus); // API call
    if (response?.data) {
      const { active_job, type, error } = response.data;

      yield put({ type: SET_GLOBAL_ACTIVE_JOB, payload: active_job });
      yield put({ type: SET_GLOBAL_UPLOAD_TYPE, payload: type || null });
      yield put({ type: SET_UPLOAD_ERROR, payload: error });


    } else {
      yield put({ type: SET_ERROR, payload: 'Invalid response from server.' });
    }
  } catch (error) {
    yield put({ type: SET_ERROR, payload: error.message });
  }
}

function* pollingSaga() {
  try {
    yield put({ type: START_POLLING }); // Set polling to true
    const startTime = Date.now(); // Track the start time

    while (true) {
      yield call(fetchJobStatusSaga); // Fetch job status

      const globalSt = yield select((state) => state.global);
      const activeJob=globalSt.activeJob;

      // Calculate elapsed time in milliseconds
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime >= (20*60*1000)) { 
        if (!activeJob) {
          break; // Exit the loop and stop polling
        }
      }


        if (!activeJob) {
          break; // Exit the loop and stop polling
        }





      yield delay(20000); // Delay between polls (20 seconds)
    }
  } finally {
    yield put({ type: STOP_POLLING }); // Ensure polling stops
  }
}

function* watchPollingSaga() {
  yield takeLatest(FETCH_JOB_STATUS, pollingSaga);
}

export default watchPollingSaga;
