import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  dropdownData,
  fetchData,
  academics,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const colorNameMap = {
  "#FFC107": "Amber",
  "#FFC0CB": "Pink",
  "#FF9800": "Orange",
  "#FF7043": "Deep Orange",
  "#FF5722": "Orange Red",
  "#F5F5F5": "White Smoke",
  "#F48BCA": "Lavender Pink",
  "#E91E63": "Pink Raspberry",
  "#E5E4E2": "Platinum",
  "#E1BB37": "Sunflower",
  "#D32F2F": "Fire Brick",
  "#9C27B0": "Purple",
  "#698948": "Olive",
  "#673AB7": "Deep Purple",
  "#607D8B": "Blue Grey",
  "#4D77C9": "Sky Blue",
  "#4CAF50": "Green",
  "#45C4BC": "Aquamarine",
  "#3F51B5": "Indigo",
  "#37474F": "Charcoal",
  "#30D5C8": "Turquoise",
  "#00BCD4": "Cyan",
  "#00ACC1": "Deep Turquoise",
  "#009688": "Teal",
  "#007BFF": "Blue",
  "#003366": "Navy Blue",
  "#FFEB3B": "Yellow",
  "#CDDC39": "Lime",
};

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
};

const formatOptionLabel = ({ label, color }) => {
  const formattedLabel = label
    ? label.toLowerCase().replace(/\s+/g, "")
    : "whitesmoke";

  const colorCode =
    Object.keys(colorNameMap).find(
      (key) =>
        colorNameMap[key].toLowerCase().replace(/\s+/g, "") === formattedLabel
    ) || "#ffffff";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: colorCode,
          width: 18,
          height: 18,
          marginRight: 10,
          borderRadius: "50%",
        }}
      />
      {label}
    </div>
  );
};

const AcademicTagCreate = () => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();
  const [selectedTagColoreDropdown, setSelectedTagColorDropdown] =
    useState(null);
  const [tagColorList, setTagColorList] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    tag_name: "",
    tag_color_id: "",
    is_default: 0,
    is_deleted: 0,
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTagColorDropdownData();
      let payload = {
        modelName: "tag_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTagColorDropdownData(data.tag_color_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTagColorDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "tag_color_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      let mappedResponseData = responseData.data.map((item) => ({
        ...item,
        tag_color_name: colorNameMap[item.tag_color_name],
      }));

      const dropdownResponseData = await dropdownData(
        mappedResponseData,
        "tag_color_name"
      );

      if (id) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value === id) {
            setSelectedTagColorDropdown(element);
          }
        });
      }

      setTagColorList(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTagColorSelectChange = (selectedTagColorDropdown) => {
    setSelectedTagColorDropdown(selectedTagColorDropdown);
    setFormData((prevState) => ({
      ...prevState,
      tag_color_id: selectedTagColorDropdown
        ? selectedTagColorDropdown.value
        : "",
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.tag_name ) {
      return toast.warning("Tag name is required");
    }
    if (!formData.tag_color_id){
      return toast.warning("Tag color is required")
    }
    try {
      setLoadingData(true);

      var payload = {
        modelName: "tag_masters",
        inputData: formData,
      };

      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData.code == 1) {
        toast.success("Academic Tag saved successfully!");
        navigate("/academic-tag-list");
      } else {
        toast.error("Error while saving Academic Tag!");
        navigate("/academic-tag-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/academic-tag-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC TAG"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Tag Name <Asterisk />
                              </Label>
                              <AvField
                                name="tag_name"
                                placeholder="Tag name"
                                type="text"
                                errorMessage="Enter Tag Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.tag_name}
                              />
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">Tag Color <Asterisk/></Label>
                              <Select
                                value={selectedTagColoreDropdown}
                                onChange={handleTagColorSelectChange}
                                options={tagColorList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={customStyles}
                                validate={{
                                  required: { value: true },
                                }}
                                formatOptionLabel={formatOptionLabel}
                                isClearable
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="4">
                            <div
                              style={{ display: "inline-flex", gap: "14px" }}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.is_default}
                                  id="is_default"
                                  name="is_default"
                                  checked={formData.is_default}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="is_default">
                                  Is Default
                                </label>
                              </div>

                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.is_active}
                                  id="invalidCheck"
                                  name="is_active"
                                  checked={formData.is_active}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Is Active
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicTagCreate;
