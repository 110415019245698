import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, social, user } from "../../../services/fetchData";
const breadcrumbItems = [];

const GroupBroadCastList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      const responseData = await fetchData(
        "getBroadcastNotification",
         {},
        social
      );
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/group-broadcast-create");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Group Id",
        accessor: (row) => (row.group_id && row.group_id ? row.group_id : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Sender Name",
        accessor: (row) => (row.sender_user_name && row.sender_user_name ? row.sender_user_name : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Title",
        accessor: (row) => (row.title && row.title ? row.title : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Member Count",
        accessor: (row) =>
          row.count ? row.count : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Created At",
        accessor: (row) =>
          row.createdAt ? convertToDateFormat(row.createdAt) : "-",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  function convertToDateFormat(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Group Broadcast List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Send Group Broadcast
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns ||[]}
                data={data|| []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupBroadCastList;
