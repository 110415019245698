import {
  FETCH_JOB_STATUS,
  START_POLLING,
  STOP_POLLING,
  SET_GLOBAL_ACTIVE_JOB,
  SET_GLOBAL_UPLOAD_TYPE,
  SET_UPLOAD_ERROR,
  SET_ERROR,
} from './actionTypes';

export const fetchJobStatus = () => ({ type: FETCH_JOB_STATUS });
export const startPolling = () => ({ type: START_POLLING });
export const stopPolling = () => ({ type: STOP_POLLING });
export const setActiveJob = (activeJob) => ({ type: SET_GLOBAL_ACTIVE_JOB, payload: activeJob });
export const setUploadType = (uploadType) => ({ type: SET_GLOBAL_UPLOAD_TYPE, payload: uploadType });
export const setUploadError = (error) => ({ type: SET_UPLOAD_ERROR, payload: error });
export const setError = (error) => ({ type: SET_ERROR, payload: error });
