import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import withRouter from "../Common/withRouter";
import { fetchData, user } from "../../services/fetchData";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.router.location.pathname,
      menuData: [],
      accessMenuData: [],
      activeMenuId: null,
    };
  }

  componentDidMount() {
    Promise.all([this.getMenuData(), this.getAccessMenuData()]).then(() => {
      this.initMenu();
    });
  }

  async getMenuData() {
    try {
      const payload = {
        modelName: "menu_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({ menuData: responseData.data }, () => {
          this.initMenu();
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async getAccessMenuData() {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        modelName: "access_controls",
        whereCondition: {
          user_id: authID[0].id,
          access: 1,
          is_active: 1,
        },
        pagination: {
          page: 1,
          pageSize: 1000000,
        },
      };
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({ accessMenuData: responseData.data }, () => {
          this.initMenu();
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  UNSAFE_componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname
    ) {
      this.setState({ pathName: this.props.router.location.pathname }, () => {
        this.initMenu();
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // Ensure menu is reinitialized on state change
    if (
      prevState.menuData !== this.state.menuData ||
      prevState.accessMenuData !== this.state.accessMenuData
    ) {
      this.initMenu();
    }
  }

  initMenu() {
    // Remove any existing active classes
    const ul = document.getElementById("side-menu");
    if (ul) {
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove("active");
        const parent = items[i].parentElement;
        if (parent) {
          parent.classList.remove("mm-active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.remove("mm-show");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove("mm-active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove("mm-active");
              }
            }
          }
        }
      }
    }

    // Initialize MetisMenu
    new MetisMenu("#side-menu");
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.menuData !== nextState.menuData ||
      this.state.accessMenuData !== nextState.accessMenuData ||
      this.props.router.location.pathname !== nextProps.router.location.pathname
    );
  }

  render() {
    const { t } = this.props;
    const { menuData, accessMenuData } = this.state;
    const accessMenuId = accessMenuData.map((item) => item.menu_id);
    const newfilterMenuData = menuData.filter((item) =>
      accessMenuId.includes(item.id)
    );

    // Get the current pathname
    const currentPath = this.props.router.location.pathname;

    // Define the inline style for the active sub-menu item
    const activeStyle = {
      color: "white",
    };

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            {menuData
              .filter((item) =>
                newfilterMenuData.some((child) => child.parent_id == item.id)
              )
              .sort((a, b) => a.sequence - b.sequence)
              .map((item, index) => (
                <li key={index}>
                  <Link
                    style={currentPath === item.menu_url ? activeStyle : {}}
                    to={item.menu_url}
                    className={`has-arrow waves-effect`}>
                    <i className={item.web_menu_icon}></i>
                    <span className="ms-1">{t(item.menu_name)}</span>
                  </Link>

                  <ul className="sub-menu">
                    {newfilterMenuData
                      .filter((child) => child.parent_id === item.id)
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((child) => (
                        <li key={child.id}>
                          <Link
                            style={
                              currentPath === child.menu_url ? activeStyle : {}
                            }
                            to={child.menu_url}>
                            {t(child.menu_name)}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.Layout };
};
export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withTranslation()(SidebarContent))
);
