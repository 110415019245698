import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  downloadBlobData,
  dropdownData,
  fetchData,
  image_url,
  user,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";
import { useAuth } from "../../../services/useAuth";

const CMSUserCreate = ({ router }) => {
  const [breadcrumbItems] = useState([]);
  const authData = useAuth();

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedRoleDropdown, setSelectedRoleDropdown] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [roleError, setRoleError] = useState(false);
  const [originalPassword, setOriginalPassword] = useState("");
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);
  const [profileImageSrc, setProfileImageSrc] = useState("");
  const [fetchedData, setFetchedData] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: null,
      first_name: "",
      last_name: "",
      mobile_no: "",
      email_id: "",
      role_id: 1,
      username: "",
      password: "",
      type: 1,
      is_active: 1,
    },
    uploadImage: {
      field_name: "image",
      folderPath: "",
      imagePath: "",
    },
  });

  useEffect(() => {
    getData(id);
    getTenantDropdownData();
  }, [authData]);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.password) {
        setOriginalPassword(data.password);
      }
      if (data.image) {
        setProfileImageSrc(image_url + data.image);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getRoleDropdownData();
      let payload = {
        modelName: "cms_user_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          getTenantDropdownData(responseData.data[0]?.tenant_id);
          getRoleDropdownData(responseData.data[0].role_id);
          setFetchedData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getRoleDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "role_masters",
        relations: [
          {
            module: "tenant_masters",
            moduleas: "tenant_masters",
          },
        ],
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "role_name"
      );

      if (id) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == id) {
            setSelectedRoleDropdown(element);
          }
        });
      } else if (authData && authData?.type == 2) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == 2) {
            setSelectedRoleDropdown(element);
          }
        });
      } else {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == 1) {
            setSelectedRoleDropdown(element);
          }
        });
      }
      setRoleList(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    setSelectedRoleDropdown("");
    selectedTenant
      ? setSelectedRoleDropdown({ label: "User", value: 2 })
      : setSelectedRoleDropdown({ label: "Admin", value: 1 });
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedTenant ? selectedTenant?.value : null,
        role_id: selectedTenant ? 2 : 1,
      },
    }));
  };

  const handleRoleSelectChange = (selectedRoleDropdown) => {
    setSelectedRoleDropdown(selectedRoleDropdown);
    // setRoleError(!selectedRoleDropdown);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        role_id: selectedRoleDropdown ? selectedRoleDropdown.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setProfileImageSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // handle media download
  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData(user, lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setProfileImageSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        image: "",
      },
    }));
  };

  const validateForm = () => {
    const { mobile_no, email_id, password } = formData.inputData;
    const phoneRegex = /^\d{10,12}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-z][a-zA-Z0-9!@#$%^&*]{7,}$/;

    if (!phoneRegex.test(mobile_no)) {
      toast.error("Please enter a valid number.");
      return false;
    }

    if (!emailRegex.test(email_id)) {
      toast.error("Please enter a valid email.");
      return false;
    }

    // if (!passwordRegex.test(password)) {
    //   toast.error("Please enter a valid password.");
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.inputData.first_name ||
      !formData.inputData.last_name ||
      !formData.inputData.email_id ||
      !formData.inputData.mobile_no ||
      !formData.inputData.password ||
      !formData.inputData.username
    ) {
      return toast.warning("All fields are required");
    }

    if (!validateForm()) {
      return;
    }

    try {
      setLoadingData(true);
      if (formData.inputData.password === originalPassword) {
        delete formData.inputData.originalPassword;
      }

      formData.inputData.type = formData.inputData.role_id;

      var payload = {
        modelName: "cms_user_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (authData && authData?.type == 2) {
        payload.inputData.tenant_id = authData?.tenant_id;
        payload.inputData.role_id = 2;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        user
      );

      if (responseData.code == 1) {
        toast.success("CMS User saved successfully!");
        navigate("/cms-user-list");
      } else {
        toast.error("Error While saving CMS User!");
        navigate("/cms-user-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/cms-user-list"); // Navigate to the desired route
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE CMS USER"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          {authData && authData?.type == 1 && (
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">Tenant</Label>
                                <Select
                                  value={selectedTenant}
                                  onChange={handleSelectChange}
                                  options={tenantlist}
                                  classNamePrefix="select2-selection"
                                  noOptionsMessage={() => "no data found.."}
                                  maxMenuHeight={200}
                                  isClearable
                                />
                              </div>
                            </Col>
                          )}
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                First Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="first_name"
                                placeholder="First name"
                                type="text"
                                errorMessage="Enter First Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{ required: { value: true } }}
                                value={formData.inputData.first_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Last Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="last_name"
                                placeholder="Last name"
                                type="text"
                                errorMessage="Enter Last Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{ required: { value: true } }}
                                value={formData.inputData.last_name}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Mobile Number
                                <Asterisk />
                              </Label>
                              <AvField
                                name="mobile_no"
                                placeholder="Mobile Number"
                                type="phone"
                                errorMessage="Enter Mobile Number"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[0-9]{10,12}$",
                                    errorMessage:
                                      "Mobile number must be between 10 and 12 digits",
                                  },
                                }}
                                value={formData.inputData.mobile_no}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Email Id
                                <Asterisk />
                              </Label>
                              <AvField
                                name="email_id"
                                placeholder="Email Id"
                                type="text"
                                errorMessage="Enter Email Id"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/",
                                    errorMessage: "Enter Valid Email Id",
                                  },
                                }}
                                value={formData.inputData.email_id}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                User Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="username"
                                placeholder="User Name"
                                type="text"
                                errorMessage="Enter User Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.username}
                              />
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Role
                                {/* {!selectedTenant && <Asterisk />} */}
                              </Label>
                              <Select
                                value={selectedRoleDropdown}
                                onChange={handleRoleSelectChange}
                                options={roleList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                                isDisabled={
                                  !!selectedTenant || authData?.type == 2
                                }
                              />
                              {/* {roleError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "#ff4d4d",
                                    }}>
                                    Select Role
                                  </p>
                                </span>
                              )} */}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="password">
                                Password
                                <Asterisk />
                              </Label>
                              <AvField
                                name="password"
                                placeholder="Password"
                                type="password"
                                errorMessage="Enter Password"
                                className="form-control"
                                value={formData.inputData.password}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              {" "}
                              <Label
                                className="form-label"
                                htmlFor="profile_image">
                                Upload profile picture
                              </Label>
                              <input
                                name="profile_image"
                                type="file"
                                className="form-control"
                                onChange={handleProfileImageChange}
                              />
                            </div>
                            {profileImageSrc && (
                              <Col md="6" className="mt-1">
                                <div className="mb-3 d-flex">
                                  <img
                                    src={profileImageSrc}
                                    alt={"profile image"}
                                    width="120px"
                                    height="100px"
                                    style={{
                                      border: ".5px solid gray",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  {!isMediaRemoveAndDownload && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "15px",
                                        }}>
                                        {loadingMediaImageData ? (
                                          <LoaderDownloadMedia />
                                        ) : (
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadMediaClick(
                                                profileImageSrc
                                              )
                                            }
                                          />
                                        )}
                                        <img
                                          src={removeIcon}
                                          alt="remove"
                                          width="30px"
                                          height="30px"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={handleRemoveMediaClick}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CMSUserCreate;
