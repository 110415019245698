import { useState, useEffect } from "react";

export const useAuth = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    let storedUser = localStorage.getItem("logedin-user");
    if (storedUser) {
      storedUser = JSON.parse(storedUser);
      setAuth(storedUser[0]);
    }
  }, []);

  return auth;
};
