import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, user } from "../../../services/fetchData";
import { useAuth } from "../../../services/useAuth";

const breadcrumbItems = [];

const TenantUserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const authData = useAuth();

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];
      let payload = {
        modelName: "cms_user_masters",
        relations: [
          {
            module: "temp_tenant_masters",
            moduleas: "temp_tenant_masters",
          },
        ],
        search: {
          field_name: ["first_name", "last_name", "email_id"],
          searchKeyword: searchKeyword,
        },
        whereCondition: {
          is_active: 1,
          type: 2,
        },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      };

      if (authID.type == 1 && tenantId) {
        payload.whereCondition.tenant_id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.tenant_id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/tenant-user-create");
  };

  const handleEditClick = (id) => {
    navigate(`/tenant-user-create/${id}`);
  };

  const columns = useMemo(() => {
    if (!authData) return [];
    return [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      ...(authData?.type !== 2
        ? [
            {
              Header: "Tenant Name",
              accessor: (row) =>
                row.tenant_masters && row.tenant_masters.tenant_company_name
                  ? row.tenant_masters.tenant_company_name
                  : "-",
              disableFilters: true,
              filterable: false,
            },
          ]
        : []),
      {
        Header: "First Name",
        accessor: (row) =>
          row.first_name && row.first_name ? row.first_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Last Name",
        accessor: (row) =>
          row.last_name && row.last_name ? row.last_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: (row) => (row.email_id && row.email_id ? row.email_id : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: (row) =>
          row.mobile_no && row.mobile_no ? row.mobile_no : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)} // Assuming `id` is the unique identifier
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      },
    ];
  }, [authData]);

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Tenant User List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Tenant User
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TenantUserList;
