import React, { useMemo, useState, useEffect, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, social } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const PostHashtagMasterList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];
      let payload = {
        modelName: "hashtag_masters",
        whereCondition: {
          is_deleted: 0,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.tenant_id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.tenant_id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload, social);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/post-hashtag-create");
  };

  const handleEditClick = (id) => {
    navigate(`/post-hashtag-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        id: id,
        modelName: "hashtag_masters",
        inputData: {
          is_deleted: 1,
        },
      };

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        social
      );
      if (responseData && responseData.code === 1) {
        toast.success("Post Hashtag Deleted!");
        getData();
      } else {
        toast.error("Error while deleting the Hashtag.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Hashtag?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Post Hashtag Name",
        accessor: (row) =>
          row.hashtag_name && row.hashtag_name ? row.hashtag_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Post Hashtag List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Hashtag
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PostHashtagMasterList;
