import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// Import Images
import { fetchData } from "../../services/fetchData";

const MegaMenu = ({ t }) => {
  const [megaMenu, setMegaMenu] = useState(false);
  const [data, setData] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const fetchDataAsync = async () => {
    try {
      let authID = localStorage?.getItem("logedin-user");
      if (!authID?.trim?.()) return;
      
      try {
        authID = JSON.parse(authID);
      } catch (e) {
        console.error("Error parsing authID:", e);
        return;
      }
      
      if (!authID || !Array.isArray(authID) || authID.length === 0) return;
      authID = authID[0];
      
      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1
        },
      };
      
      // If user has a specific tenant, only fetch that tenant
      if (authID?.type === 2 && authID?.tenant_id) {
        payload.whereCondition.id = authID.tenant_id;
      }
      
      const responseData = await fetchData?.("getMasterList", payload);
      
      if (responseData?.code === 1 && Array.isArray(responseData?.data)) {
        const validData = responseData?.data?.filter(element => 
          element && 
          typeof element === 'object' &&
          element?.tenant_company_name
        );

        // If no valid data found, try fetching tenant 1
        if (!validData?.length) {
          const defaultPayload = {
            modelName: "tenant_masters",
            whereCondition: {
              is_active: 1,
              id: 1
            },
          };
          
          const defaultResponse = await fetchData?.("getMasterList", defaultPayload);
          if (defaultResponse?.code === 1 && Array.isArray(defaultResponse?.data) && defaultResponse?.data?.length > 0) {
            const defaultTenant = defaultResponse.data[0];
            setData([defaultTenant]);
            setSelectedTenant(defaultTenant);
            localStorage?.setItem("tenantId", "1");
            setIsDropdownDisabled(true); // Disable dropdown for default tenant
          }
          return;
        }
        
        validData?.forEach((element, index) => {
          if (element) element.index = (index + 1) || 0;
        });
        
        const sortedData = validData?.sort((a, b) => (b?.index || 0) - (a?.index || 0));
        setData(sortedData);
        
        // If user has a specific tenant, select it and disable dropdown
        if (authID?.type === 2 && authID?.tenant_id) {
          const userTenant = sortedData?.find(t => t?.id === authID.tenant_id);
          if (userTenant) {
            setSelectedTenant(userTenant);
            localStorage?.setItem("tenantId", userTenant.id?.toString());
            setIsDropdownDisabled(true); // Disable dropdown for user's tenant
            return;
          }
        }
        
        // If there's a stored tenant, select it and disable dropdown
        const storedTenantId = localStorage?.getItem("tenantId");
        if (storedTenantId) {
          const foundTenant = sortedData?.find(t => t?.id?.toString() === storedTenantId);
          if (foundTenant) {
            setSelectedTenant(foundTenant);
            // setIsDropdownDisabled(true); // Disable dropdown for stored tenant
            return;
          }
        }
        
        // If no tenant is selected yet, select first tenant and disable dropdown
        if (sortedData?.length > 0) {
          const firstTenant = sortedData[0];
          setSelectedTenant(firstTenant);
          localStorage?.setItem("tenantId", firstTenant.id?.toString());
          // setIsDropdownDisabled(true); // Disable dropdown for first tenant
        }
      }
    } catch (error) {
      console.error("Error in fetchDataAsync:", error);
      // Try to set default tenant 1 on error
      try {
        const defaultPayload = {
          modelName: "tenant_masters",
          whereCondition: {
            is_active: 1,
            id: 1
          },
        };
        
        const defaultResponse = await fetchData?.("getMasterList", defaultPayload);
        if (defaultResponse?.code === 1 && Array.isArray(defaultResponse?.data) && defaultResponse?.data?.length > 0) {
          const defaultTenant = defaultResponse.data[0];
          setData([defaultTenant]);
          setSelectedTenant(defaultTenant);
          localStorage?.setItem("tenantId", "1");
          setIsDropdownDisabled(true); // Disable dropdown for error fallback
        }
      } catch (e) {
        console.error("Error setting default tenant:", e);
      }
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, []);

  const handleSetTenantId = (tenant) => {
    if (!tenant?.id) return;
    
    try {
      localStorage?.setItem("tenantId", tenant?.id?.toString() || "");
      setSelectedTenant(tenant);
      setMegaMenu(false);
      setIsDropdownDisabled(true);
      window.location?.reload();
    } catch (error) {
      console.error("Error setting tenant:", error);
    }
  };

  const disabledStyle = {
    filter: 'grayscale(100%)',
    pointerEvents: 'none'
  };

  const handleHeaderClick = (e) => {
    e.preventDefault();
    setMegaMenu(prevState => !prevState);
  };

  if (!t) return null;

  return (
    <div style={isDropdownDisabled ? { pointerEvents: 'none' } : {}}>
      <Dropdown
        className={`tenant-dropdown ${typeof ms === 'number' ? 'ms-2' : ''}`}
        isOpen={Boolean(megaMenu)}
        toggle={() => {}}
        disabled={Boolean(isDropdownDisabled)}
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn btn-sm d-flex align-items-center header-item"
          disabled={Boolean(isDropdownDisabled)}
          style={isDropdownDisabled ? { ...disabledStyle } : undefined}
          onClick={handleHeaderClick}
        >
          <span className="me-2">
            {selectedTenant?.tenant_company_name?.toString()?.trim() || t?.("Select Tenant") || "Select Tenant"}
          </span>
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu className="tenant-dropdown-menu shadow-sm">
          {Array.isArray(data) && data?.length > 0 ? (
            data.map((tenant) => {
              if (!tenant?.id || !tenant?.tenant_company_name) return null;
              
              return (
                <div
                  key={tenant?.id?.toString()}
                  className={`dropdown-item ${selectedTenant?.id === tenant?.id ? 'active' : ''}`}
                  onClick={() => tenant && handleSetTenantId(tenant)}
                >
                  {tenant?.tenant_company_name?.toString()?.trim() || ""}
                </div>
              );
            })
          ) : (
            <div className="dropdown-item">
              {t?.("No tenants available") || "No tenants available"}
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(MegaMenu);
