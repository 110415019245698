import React, { useMemo, useState, useEffect, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";
import { useSelector } from "react-redux";

const breadcrumbItems = [];

const DefaultKeywordList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);
  const globalState = useSelector((state) => state.global);
  const activeJob = globalState.activeJob
  const uploadType=globalState.uploadType;

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      let payload = {
        modelName: "default_keywords",
        whereCondition: {
          is_deleted: 0,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/default-keyword-create");
  };

  const handleEditClick = (id) => {
    navigate(`/default-keyword-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        id: id,
        modelName: "default_keywords",
        inputData: {
          is_deleted: 1,
        },
      };

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData && responseData.code === 1) {
        toast.success("Keyword Deleted!");
        getData();
      } else {
        toast.error("Error while deleting the Keyword.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Keyword?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Keyword Name",
        accessor: (row) =>
          row.keyword_name && row.keyword_name ? row.keyword_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        width: 100,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}
              disabled={activeJob}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}
              disabled={activeJob}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    [activeJob]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Default Keyword List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}
              disabled={activeJob}>
              Create Default Keyword
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DefaultKeywordList;
