import {
  SET_GLOBAL_ACTIVE_JOB,
  SET_GLOBAL_UPLOAD_TYPE,
  SET_UPLOAD_ERROR,
  SET_ERROR,
  START_POLLING,
  STOP_POLLING,
} from './actionTypes';

const initialState = {
  activeJob: null,
  uploadType: null,
  uploadError: null,
  error: null,
  isPolling: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_ACTIVE_JOB:
      return { ...state, activeJob: action.payload };
    case SET_GLOBAL_UPLOAD_TYPE:
      return { ...state, uploadType: action.payload };
    case SET_UPLOAD_ERROR:
      return { ...state, uploadError: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case START_POLLING:
      return { ...state, isPolling: true };
    case STOP_POLLING:
      return { ...state, isPolling: false }; // Ensure activeJob is reset
    default:
      return state;
  }
};

export default reducer;
