import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Label, Container, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { dropdownData, fetchData, user } from "../../../services/fetchData";
import "react-datepicker/dist/react-datepicker.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import { useAuth } from "../../../services/useAuth";

const GuestUserCreate = (props) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  const authData = useAuth();
  let { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      name: "",
      email: "",
      short_bio: "",
      username: "",
      mobile_no: "",
      profile_image: "",
      is_active: 1,
    },
    uploadImage: {
      imagePath: "",
      folderPath: "guest_user",
      field_name: "image",
    },
  });

  useEffect(() => {
    getData(id);
  }, [authData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "user_masters",
        whereCondition: {
          type: 2,
        },
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          getTenantDropdownData(responseData.data[0].tenant_id);
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    setTenantError(!selectedTenant);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedTenant ? selectedTenant.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("checked", checked);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    const { email, mobile_no } = formData.inputData;
    const phoneRegex = /^\d{10,12}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email.");
      return false;
    }

    if (!phoneRegex.test(mobile_no)) {
      toast.error("Please enter a valid number.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (authData && authData?.type == 1) {
      if (!selectedTenant) {
        setTenantError(true);
        return toast.warning("Please select a tenant");
      } else {
        setTenantError(false);
      }
    }

    if (
      !formData.inputData.name ||
      !formData.inputData.email ||
      !formData.inputData.short_bio ||
      !formData.inputData.mobile_no ||
      !formData.inputData.username
    ) {
      return toast.warning("All fields are required");
    }

    if (!validateForm()) {
      return;
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "user_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (authData && authData?.type == 2) {
        payload.inputData.tenant_id = authData?.tenant_id;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        user
      );

      if (responseData.code == 1) {
        toast.success("Guest User saved successfully!");
        navigate("/guest-user-list");
      } else {
        toast.error("Error While saving Guest User!");
        navigate("/guest-user-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE GUEST USER"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          {authData && authData?.type == 1 && (
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Tenant
                                  <Asterisk />
                                </Label>
                                <Select
                                  value={selectedTenant}
                                  onChange={handleSelectChange}
                                  options={tenantlist}
                                  classNamePrefix="select2-selection"
                                  noOptionsMessage={() => "no data found.."}
                                  maxMenuHeight={200}
                                  isClearable
                                />
                                {tenantError && (
                                  <span>
                                    <p
                                      style={{
                                        fontSize: "11.5px",
                                        color: "red",
                                      }}>
                                      Select Tenant
                                    </p>
                                  </span>
                                )}
                              </div>
                            </Col>
                          )}
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="name"
                                placeholder="Name"
                                type="text"
                                errorMessage="Enter Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.inputData.name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Email Address
                                <Asterisk />
                              </Label>
                              <AvField
                                name="email"
                                placeholder="Email Address"
                                type="email"
                                errorMessage="Enter Email Address"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/",
                                    errorMessage: "Enter Valid Email",
                                  },
                                }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.inputData.email}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Username
                                <Asterisk />
                              </Label>
                              <AvField
                                name="username"
                                placeholder="Username"
                                type="text"
                                errorMessage="Enter Username"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.inputData.username}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Mobile Number
                                <Asterisk />
                              </Label>
                              <AvField
                                name="mobile_no"
                                placeholder="Mobile Number"
                                type="phone"
                                errorMessage="Enter Mobile Number"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[0-9]{10,12}$",
                                    errorMessage:
                                      "Mobile number must be between 10 and 12 digits",
                                  },
                                }}
                                value={formData.inputData.mobile_no}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              {" "}
                              <Label
                                className="form-label"
                                htmlFor="profile_image">
                                Upload profile picture
                              </Label>
                              <input
                                name="profile_image"
                                type="file"
                                className="form-control"
                                onChange={handleProfileImageChange}
                              />
                            </div>
                            {formData.uploadImage.imagePath && (
                              <Col md="6" className="mt-1">
                                <img
                                  src={formData.uploadImage.imagePath}
                                  alt={"profile image"}
                                  width="120px"
                                  height="100px"
                                  style={{
                                    border: ".5px solid gray",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Col>
                            )}
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Sort Bio
                                <Asterisk />
                              </Label>
                              <AvField
                                name="short_bio"
                                id="validationCustom01"
                                placeholder="Short bio"
                                errorMessage="Enter Short bio"
                                onChange={handleTextChange}
                                value={formData.inputData.short_bio}
                                type="textarea"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Col lg="12">
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={formData.inputData.is_active}
                              id="invalidCheck"
                              name="is_active"
                              checked={formData.inputData.is_active}
                              onChange={handleTextChange}
                              required=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invalidCheck">
                              Is Active
                            </label>
                          </div>
                        </Col>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() => navigate("/guest-user-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GuestUserCreate;
