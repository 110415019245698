import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import MiniWidgets from "./MiniWidgets";
import ActiveUserAnalytics from "./ActiveUserAnalytics";
import UserAnalytics from "./UserAnalytics";
import JoinedUsers from "./JoinedUsers";
import { fetchDashboardData, refreshAuthTD } from "../../services/fetchData";

const Dashboard = () => {
    const [breadcrumbItems] = useState([
        { title: "Growth AXL", link: "#" },
        { title: "Dashboard", link: "#" },
    ]);

    const [apiData, setApiData] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState("ibo");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

    const handleYearChange = (event) => {
        const year = event.target.value;
        setSelectedYear(year);
        fetchUserData();
        // Call API with the selected year
    };

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = 2024; year <= currentYear; year++) {
        years.push(year);
    }


    const fetchUserData = useCallback(async (token, selectedYear) => {
        let isMounted = true;
        try {
            const data = await fetchDashboardData(token, selectedYear);
            if (isMounted) {
                setApiData(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchUserDataAndToken = async () => {
            if (isMounted) {
                setLoading(true);
            }
            try {
                const initialToken = await refreshAuthTD();
                if (initialToken && isMounted) {
                    await fetchUserData(initialToken, selectedYear);
                } else if (isMounted) {
                    console.error("Failed to get token");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchUserDataAndToken();
        return () => {
            isMounted = false;
        };
    }, [fetchUserData, selectedYear]);

    const handleUserTypeChange = (type) => {
        setSelectedUserType(type);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const buttonStyle = {
        minWidth: '100px',
        height: '50px'
    };

    const reports = useMemo(() => {
        return selectedUserType === "ibo" ?
            [{ icon: "ri-group-line", title: "Number of Users", value: apiData.iboUsersData?.totalNoOfUsers || 0 }] :
            [
                { icon: "ri-group-line", title: "Number of Users", value: apiData.guestUsersData?.totalNoOfUsers || 0 },
                { title: "IBO Users", value: apiData.iboUsersData?.totalNoOfUsers || 0 },
                // ... other Guest specific reports
            ];
    }, [selectedUserType, apiData]);
    return (
        <React.Fragment>
            <div className="page-content position-relative" style={{ paddingTop: '75px' }}>
                <Container fluid>
                    <Row className="align-items-center mb-0">
                        <Col xs={6}>
                            <span style={{
                                fontWeight: 600,
                                fontSize: '16px',
                                paddingLeft: '5px',

                            }}>
                                Dashboard
                            </span>
                        </Col>
                        <Col xs={6} className="text-end">
                            <div className="page-title-right pt-1 ">
                                <Breadcrumbs title="" breadcrumbItems={breadcrumbItems} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                    <div className="d-flex flex-wrap gap-3 mb-3 px-1 align-items-center">
            {/* Button Group */}
            <div
                className="btn-group"
                role="group"
                style={{
                    minWidth: '140px',
                    maxWidth: '200px',
                    paddingLeft:'10px',
                    width:"100%"
                }}
            >
                <button
                    type="button"
                    className={`btn ${selectedUserType === "ibo" ? "btn-primary" : "btn-outline-primary"}`}
                    style={{
                        flex: 1,
                        minHeight: isSmallScreen ? "32px" : "40px",
                        fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                    onClick={() => handleUserTypeChange("ibo")}
                >
                    IBO
                </button>
                <button
                    type="button"
                    className={`btn ${selectedUserType === "guest" ? "btn-primary" : "btn-outline-primary"}`}
                    style={{
                        flex: 1,
                        minHeight: isSmallScreen ? "32px" : "40px",
                        fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                    onClick={() => handleUserTypeChange("guest")}
                >
                    Guest
                </button>
            </div>

            {/* Dropdown */}
            <div className="d-flex align-items-center ms-auto  gap-2"  style={{ marginRight: '15px' }}>
                <label  className="mb-0  ">
                    Year
                </label>
                <select
                    id="year-select"
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="form-select"
                    style={{
                        width: 'auto',
                        minWidth: '120px',
                        height: isSmallScreen ? "32px" : "40px",
                        fontSize: isSmallScreen ? "12px" : "14px",
                    }}
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        </div>

                    </Row>

                    <Row>
                        <Col md={8}>
                            <Row className="g-3">
                                <MiniWidgets reports={reports} loading={loading} />
                            </Row>

                            <div className="mt-3">
                                {selectedUserType === "ibo" ?
                                    <ActiveUserAnalytics
                                        graphData={selectedUserType === "ibo"
                                            ? apiData.iboUsersData?.activityGraphData
                                            : apiData.guestUsersData?.activityGraphData
                                        }
                                        loading={loading}
                                    /> : <Col xs={12}>
                                        <JoinedUsers
                                            graphData={selectedUserType === "ibo"
                                                ? apiData.iboUsersData?.joiningGraphData
                                                : apiData.guestUsersData?.joiningGraphData
                                            }
                                        />
                                    </Col>
                                }
                            </div>


                        </Col>

                        <Col md={4} className="mt-3 mt-md-0">
                            <UserAnalytics
                                loading={loading}
                                android={selectedUserType === "ibo"
                                    ? apiData.iboUsersData?.androidUsers || 0
                                    : apiData.guestUsersData?.androidUsers || 0
                                }
                                ios={selectedUserType === "ibo"
                                    ? apiData.iboUsersData?.iosUsers || 0
                                    : apiData.guestUsersData?.iosUsers || 0
                                }
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        {selectedUserType === "ibo" ?
                            <Col xs={12}>
                                <JoinedUsers
                                    graphData={selectedUserType === "ibo"
                                        ? apiData.iboUsersData?.joiningGraphData
                                        : apiData.guestUsersData?.joiningGraphData
                                    }
                                />
                            </Col> : null
                        }

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
