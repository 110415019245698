import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import styled from "styled-components";

const StyledCard = styled(Card)`
  background: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  height: 100px;
  width: 100%;
  margin-bottom: 5px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
`;

const IconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.25rem;
`;

const IconWrapper = styled.div`
  font-size: ${props => props.$windowWidth < 768 ? '1.2rem' : '1.4rem'};
  color: ${(props) => props.color};
  margin-right: 0.25rem;
`;

const Title = styled.h6`
  font-weight: 600;
  margin-bottom: 0;
  font-size: ${props => props.$windowWidth < 768 ? '0.8rem' : '0.85rem'};
  text-align: left;
  flex: 1;
  margin-left: 12px;
`;

const SkeletonPlaceholder = styled.div`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "1rem"};
  background: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
  margin-bottom: ${(props) => props.marginBottom || "0"};

  @keyframes shimmer {
    0% {
      background-position: -100px;
    }
    100% {
      background-position: 200px;
    }
  }
`;

const MiniWidgets = ({ loading, reports }) => {
  const colors = ["#007bff", "#28a745", "#ffc107", "#17a2b8"];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderSkeletons = (count = 1) => (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Col key={index} xs={12}>
          <StyledCard>
            <CardBody>
              <CardContent>
                <IconTitleWrapper>
                  <IconWrapper $windowWidth={windowWidth}>
                    <SkeletonPlaceholder width="30px" height="30px" />
                  </IconWrapper>
                  <Title $windowWidth={windowWidth}>
                    <SkeletonPlaceholder width="70%" />
                  </Title>
                </IconTitleWrapper>
                <SkeletonPlaceholder width="50%" height="1.2rem" />
              </CardContent>
            </CardBody>
          </StyledCard>
        </Col>
      ))}
    </>
  );

  return (
    <div className="dashboard-stats">
      <Row className="g-1">
        {loading  ? (
          renderSkeletons() // Render a single skeleton loader
        ) : reports?.some((report) => report.title === "Number of Users") ? (
          <Col xs={12}>
            <StyledCard>
              <CardBody>
                <CardContent>
                  <IconTitleWrapper>
                    <IconWrapper $windowWidth={windowWidth} color={colors[0]}>
                      <i className={reports.find(report => report.title === "Number of Users").icon || "ri-user-line"}></i>
                    </IconWrapper>
                    <Title $windowWidth={windowWidth}>Number of Users (All Time)</Title>
                  </IconTitleWrapper>
                    <h4>{reports.find(report => report.title === "Number of Users").value.toLocaleString()}</h4>
                  
                </CardContent>
              </CardBody>
            </StyledCard>
          </Col>
        ):"" }
      </Row>
    </div>
  );
};

export default MiniWidgets;
