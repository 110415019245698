import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import videoIMG from "../../../assets/images/users/avatar-10.jpg";
import pdfIMG from "../../../assets/images/users/avatar-11.webp";
import audioIMG from "../../../assets/images/users/avatar-12.jpg";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

import {
  dropdownData,
  fetchData,
  image_url,
  academics,
  downloadBlobData,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { dropStyle } from "../../../Style/dropStyle";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import { useAuth } from "./../../../services/useAuth";

const mediaList = [
  {
    options: [
      { label: "Audio", value: "Audio" },
      { label: "Video", value: "Video" },
      { label: "Document", value: "Document" },
    ],
  },
];
const AcademicPostCreate = () => {
  const breadcrumbItems = [];

  const navigate = useNavigate();
  const authData = useAuth();
  let { id } = useParams();

  let inputRef = useRef();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);

  const [scrMediaFile, setSrcMediaFile] = useState("");
  const [scrImageHeader, setScrImageHeader] = useState("");

  //selected Group
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState([]);
  const [previousSelectedGroup, setPreviousSelectedGroup] = useState([]);
  const [groupError, setGroupError] = useState(false);

  //selected Audiance
  const [selectedAudiance, setSelectedAudiance] = useState([]);
  const [selectedAudianceOption, setSelectedAudianceOption] = useState([]);
  const [previousSelectedAudiance, setPreviousSelectedAudiance] = useState([]);
  const [audianceError, setAudianceError] = useState(false);

  //selected Media
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [mediaError, setMediaError] = useState(false);

  //selected keyword
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [selectedKeywordOption, setSelectedKeywordOption] = useState([]);

  // selected chanel  & module (only for edit)
  const [channelText, setChannelText] = useState("");
  const [moduleText, setModuleText] = useState("");

  // selected chanel  & module
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelList, setChannelList] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleList, setModuleList] = useState([]);

  const [durationError, setDurationError] = useState(false);

  //loading
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [loadingMediaFileData, setMediaFileLoadingData] = useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  // media preview
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);

  const [fetchedData, setFetchedData] = useState(null);

  // Define arrays of supported MIME types
  const documentTypes = ["application/pdf"];
  const audioTypes = [
    "audio/mp3",
    "audio/x-m4a",
    "audio/m4a",
    "audio/mpeg",
    "audio/wav",
    "audio/ogg",
    "audio/flac",
  ];
  const videoTypes = [
    "video/mp4",
    "video/x-m4a",
    "video/m4a",
    "video/webm",
    "video/avi",
    "video/mov",
    "video/mkv",
    "video/flv",
  ];

  // Define arrays of supported extensions
  const audioExtensions = [
    ".mp3",
    ".x-m4a",
    ".m4a",
    ".mpeg",
    ".wav",
    ".ogg",
    ".flac",
  ];
  const videoExtensions = [
    ".mp4",
    ".x-m4a",
    ".m4a",
    ".webm",
    ".avi",
    ".mov",
    ".mkv",
    ".flv",
  ];
  const documentExtensions = [".pdf"];
  //".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"
  const [formChannelModuleData, setFormChannelModuleData] = useState({
    inputData: {
      channel_id: null,
      module_id: null,
      is_active: 1,
    },
  });

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      header_image: "",
      title: "",
      subtitle: "",
      keyword: [],
      speaker_name: "",
      transcript: "",
      version: "",
      released_date: "",
      duration: "00:00:00",
      thumbnail_picture_link: "",

      media: {
        title: "",
        type: "",
        url: "",
        content: "",
      },
      type: 1,

      setting: [
        { type: 1, checked: false }, // hide header
        { type: 2, checked: false }, // hide title
        { type: 3, checked: false }, // hide follows
        { type: 4, checked: false }, // enable
      ],

      academic_channel_post_group_linkings: [],
      academic_channel_post_audience_linkings: [],

      share: [1],
      view: [1],
      click: [1],
      shareable: 0,
      is_active: 1,
    },

    uploadImage: [
      {
        imagePath: "",
        folderPath: "",
        field_name: "header_image",
      },
      {
        imagePath: "",
        folderPath: "",
        field_name: "media.url",
      },
    ],
  });

  useEffect(() => {
    if (authData && authData?.type == 2) {
      if (authData?.tenant_id) {
        getSelectChannelDropData(authData?.tenant_id);
      } else {
        setSelectedChannel([]);
        setChannelList([]);
      }
    }

    getData(id);
    getSelectGroupIdDropData();
    getSelectAudianceIdDropData();
    getSelectKeywordDropData();

    if (id) {
      getChannelData();
    }
  }, [authData]);

  useEffect(() => {
    if (fetchedData) {
      const defaultSetting = [
        { type: 1, checked: false },
        { type: 2, checked: false },
        { type: 3, checked: false },
        { type: 4, checked: false },
      ];

      let mappedData = {
        ...fetchedData,
        setting:
          Array.isArray(fetchedData.setting) && fetchedData.setting.length > 0
            ? fetchedData.setting
            : defaultSetting,
      };

      let data = mappedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.header_image) {
        setScrImageHeader(image_url + `${data.header_image}`);
      }

      if (data.media) {
        setSrcMediaFile({
          mediaType: data.media.type,
          mediaURL: image_url + `${data.media.url}`,
        });
      }

      const getSelectedMedia = mediaList[0].options.find(
        (option) => option.value == data.media?.type
      );

      if (getSelectedMedia) {
        setSelectedMedia(getSelectedMedia);
      }

      const initialGroups =
        data.academic_channel_post_group_linkings &&
        data.academic_channel_post_group_linkings.map((group) => {
          return {
            id: group.id,
            post_id: group.post_id,
            value: group.group_id,
            label: group.temp_group_id_masters?.llc_,
            is_active: 1,
          };
        });
      setSelectedGroup(initialGroups);
      setPreviousSelectedGroup(initialGroups);

      const initialAudiences =
        data.academic_channel_post_audience_linkings &&
        data.academic_channel_post_audience_linkings.map((audience) => {
          return {
            id: audience.id,
            post_id: audience.post_id,
            value: audience.audience_id,
            label: audience.temp_audience?.llc_audie,
            is_active: 1,
          };
        });
      setSelectedAudiance(initialAudiences);
      setPreviousSelectedAudiance(initialAudiences);

      const initialKeyword =
        data.keyword &&
        Array.isArray(data.keyword) &&
        data.keyword.map((keyword) => {
          let keywordOption = selectedKeywordOption.find(
            (option) => option.value == keyword.keyword_id
          );

          return {
            value: keyword.keyword_id,
            label: keywordOption ? keywordOption.label : keyword.keyword_id,
          };
        });
      setSelectedKeyword(initialKeyword);
    }
  }, [
    fetchedData,
    selectedGroupOption,
    selectedAudianceOption,
    selectedKeywordOption,
  ]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();

      let payload = {
        modelName: "academic_channel_posts",
        relations: [
          {
            module: "academic_channel_post_group_linkings",
            moduleas: "academic_channel_post_group_linkings",
            subModule: "temp_group_id_masters",
            subModuleas: "temp_group_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_post_audience_linkings",
            moduleas: "academic_channel_post_audience_linkings",
            subModule: "temp_audience_id_masters",
            subModuleas: "temp_audience",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_module_associations",
            moduleas: "academic_channel_module_associations",
            isSubWhereConditionRequired: true,
          },
        ],
        subWhereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(responseData.data[0].tenant_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Group drop data
  const getSelectGroupIdDropData = async () => {
    try {
      let payload = {
        modelName: "group_id_masters",
        whereCondition: {
          is_active: 1,
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_group_id"
        );
        const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);
        setSelectedGroupOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Audiance drop data
  const getSelectAudianceIdDropData = async () => {
    try {
      let payload = {
        modelName: "temp_audience_id_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_audience_id"
        );
        const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);
        setSelectedAudianceOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSelectKeywordDropData = async () => {
    try {
      let payload = {
        modelName: "default_keywords",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "keyword_name"
        );

        const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);

        setSelectedKeywordOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getChannelData = async () => {
    try {
      let payload = {
        modelName: "academic_channel_module_associations",
        relations: [
          {
            module: "academic_channel_modules",
            moduleas: "academic_channel_modules",
          },
        ],
        whereCondition: {
          post_id: id,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.data && responseData.data.length > 0) {
        const channelTitles = responseData.data
          .map((item) => item.academic_channel_modules?.title)
          .filter(Boolean)
          .join(", ");
        setModuleText(channelTitles);

        let data = responseData.data[0];
        if (id) {
          getModuleData(data.module_id);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getModuleData = async (moduleId) => {
    try {
      let payload = {
        modelName: "academic_channel_post_associations",
        relations: [
          {
            module: "academic_channels",
            moduleas: "academic_channels",
          },
        ],
        whereCondition: {
          module_id: moduleId,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      const channelTitles = [
        ...new Set(
          responseData.data
            .map((item) => item.academic_channels?.channel_title)
            .filter(Boolean)
        ),
      ].join(", ");

      setChannelText(channelTitles);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSelectChannelDropData = async (tenant_id) => {
    try {
      let payload = {
        modelName: "academic_channels",
        whereCondition: {
          type: 1,
          is_active: 1,
          is_deleted: 0,
          tenant_id: tenant_id,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "channel_title"
        );
        setChannelList(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSelectModuleDropData = async (channelId) => {
    try {
      let payload = {
        type: 1,
        channel_id: [channelId],
      };
      const responseData = await fetchData(
        "modulelistbytype",
        payload,
        academics
      );

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "title"
        );
        setModuleList(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    if (selectedTenant) {
      getSelectChannelDropData(selectedTenant?.value);
    } else {
      setSelectedChannel([]);
      setChannelList([]);
    }
    setTenantError(!selectedTenant);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedTenant ? selectedTenant.value : "",
      },
    }));
  };

  const handleSelectChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption);
    getSelectModuleDropData(selectedOption?.value);

    setFormChannelModuleData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        channel_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleSelectModuleChange = (selectedOption) => {
    setSelectedModule(selectedOption);
    setFormChannelModuleData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        module_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleSelectGroupChange = (selectedGroupOptionValues) => {
    let currentGroupIds = selectedGroup.map((group) => group.value);
    let allGroupOptions = selectedGroupOptionValues
      .filter((option) => option.value !== "select-all")
      .map((option) => option.value);

    if (
      selectedGroupOptionValues &&
      selectedGroupOptionValues.some((option) => option.value === "select-all")
    ) {
      const selectedGroupFilterdOption = selectedGroupOption.filter(
        (option) => option.value !== "select-all"
      );
      const removedGroups = previousSelectedGroup
        .filter((group) => !allGroupOptions.includes(group.value))
        .map((group) => ({ ...group, is_active: 0 }));
      const addedGroups = selectedGroupFilterdOption
        .filter((group) => !currentGroupIds.includes(group.value))
        .map((group) => ({
          value: group.value,
          label: group.label,
          is_active: 1,
        }));

      const updatedGroups = [
        ...previousSelectedGroup.filter(
          (group) =>
            allGroupOptions.includes(group.value) && group.is_active == 1
        ),
        ...addedGroups,
        ...removedGroups,
      ];
      setSelectedGroup(
        selectedGroupOption.filter((option) => option.value !== "select-all")
      );
      setPreviousSelectedGroup(updatedGroups);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_group_linkings: updatedGroups.map((group) => ({
            id: group.id,
            post_id: group.post_id,
            group_id: group.value,
            is_active: group.is_active,
          })),
        },
      }));
    } else {
      const removedGroups = previousSelectedGroup
        .filter((group) => !allGroupOptions.includes(group.value))
        .map((group) => ({ ...group, is_active: 0 }));
      const addedGroups = selectedGroupOptionValues
        .filter((group) => !currentGroupIds.includes(group.value))
        .map((group) => ({
          value: group.value,
          label: group.label,
          is_active: 1,
        }));

      const updatedGroups = [
        ...previousSelectedGroup.filter(
          (group) =>
            allGroupOptions.includes(group.value) && group.is_active == 1
        ),
        ...addedGroups,
        ...removedGroups,
      ];
      setSelectedGroup(selectedGroupOptionValues);
      setPreviousSelectedGroup(updatedGroups);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_group_linkings: updatedGroups.map((group) => ({
            id: group.id,
            post_id: group.post_id,
            group_id: group.value,
            is_active: group.is_active,
          })),
        },
      }));
    }
  };

  const handleSelectAudienceChange = (selectedAudianceOptionValues) => {
    const currentAudienceIds = selectedAudiance.map(
      (audience) => audience.value
    );
    let allAudianceOptions = selectedAudianceOptionValues
      .filter((option) => option.value !== "select-all")
      .map((option) => option.value);

    if (
      selectedAudianceOptionValues &&
      selectedAudianceOptionValues.some(
        (option) => option.value === "select-all"
      )
    ) {
      const selectedAudianceFilterdOption = selectedAudianceOption.filter(
        (option) => option.value !== "select-all"
      );
      const removedAudiences = previousSelectedAudiance
        .filter((audience) => !allAudianceOptions.includes(audience.value))
        .map((audience) => ({ ...audience, is_active: 0 }));
      const addedAudiences = selectedAudianceFilterdOption
        .filter((audience) => !currentAudienceIds.includes(audience.value))
        .map((audience) => ({
          value: audience.value,
          label: audience.label,
          is_active: 1,
        }));

      const updatedAudiences = [
        ...previousSelectedAudiance.filter(
          (audience) =>
            allAudianceOptions.includes(audience.value) &&
            audience.is_active == 1
        ),
        ...addedAudiences,
        ...removedAudiences,
      ];

      setSelectedAudiance(
        selectedAudianceOption.filter((option) => option.value !== "select-all")
      );
      setPreviousSelectedAudiance(updatedAudiences);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_audience_linkings: updatedAudiences.map(
            (audience) => ({
              id: audience.id,
              post_id: audience.post_id,
              audience_id: audience.value,
              is_active: audience.is_active,
            })
          ),
        },
      }));
    } else {
      const removedAudiences = previousSelectedAudiance
        .filter((audience) => !allAudianceOptions.includes(audience.value))
        .map((audience) => ({ ...audience, is_active: 0 }));
      const addedAudiences = selectedAudianceOptionValues
        .filter((audience) => !currentAudienceIds.includes(audience.value))
        .map((audience) => ({
          value: audience.value,
          label: audience.label,
          is_active: 1,
        }));

      const updatedAudiences = [
        ...previousSelectedAudiance.filter(
          (audience) =>
            allAudianceOptions.includes(audience.value) &&
            audience.is_active == 1
        ),
        ...addedAudiences,
        ...removedAudiences,
      ];
      setSelectedAudiance(selectedAudianceOptionValues);
      setPreviousSelectedAudiance(updatedAudiences);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_audience_linkings: updatedAudiences.map(
            (audience) => ({
              id: audience.id,
              post_id: audience.post_id,
              audience_id: audience.value,
              is_active: audience.is_active,
            })
          ),
        },
      }));
    }
  };

  const handleSelectKeywordChange = (selectedKeywordOptionValue) => {
    if (
      selectedKeywordOptionValue &&
      selectedKeywordOptionValue.some((option) => option.value === "select-all")
    ) {
      setSelectedKeyword(
        selectedKeywordOption.filter((option) => option.value !== "select-all")
      );
      const selectedValues = selectedKeywordOption.filter(
        (option) => option.value !== "select-all"
      );

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          keyword: selectedValues.map((keyword) => ({
            keyword_name: keyword.label,
            keyword_id: keyword.value,
          })),
        },
      }));
    } else {
      setSelectedKeyword(selectedKeywordOptionValue);
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          keyword: selectedKeywordOptionValue.map((keyword) => ({
            keyword_name: keyword.label,
            keyword_id: keyword.value,
          })),
        },
      }));
    }
  };

  const handleSelecMediatTypeChange = (selectedOption) => {
    inputRef.current.value = "";
    formData.uploadImage[1].imagePath = "";
    formData.inputData.duration = "00:00:00";
    setDurationError(false);
    setSrcMediaFile("");
    setSelectedMedia(selectedOption);
    setMediaError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        media: {
          ...prevState.inputData.media,
          type: selectedOption ? selectedOption.value : "",
        },
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    const allowedTypes = {
      Audio: audioTypes,
      Video: videoTypes,
      Document: documentTypes,
    };
    // determine the expected file types based on selectedMedia.value
    const expectedTypes = allowedTypes[selectedMedia?.value] || [];

    if (file) {
      if (type === "media.url" && !expectedTypes.includes(file.type)) {
        toast.error(
          `Invalid file type. ${
            selectedMedia.value
              ? "Expected " + selectedMedia.value + " file"
              : "Select Media Type First"
          }`
        );

        e.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        let base64Data = reader.result;
        base64Data = normalizeBase64MimeType(base64Data, file.type);

        setFormData((prevState) => {
          const newUploadImage = [...prevState.uploadImage];

          let uploadImageIndex;
          if (type == "header_image") {
            uploadImageIndex = 0;
          } else if (type == "media.url") {
            uploadImageIndex = 1;
          } else {
            return prevState;
          }

          if (
            uploadImageIndex !== undefined &&
            newUploadImage[uploadImageIndex]
          ) {
            newUploadImage[uploadImageIndex].imagePath = base64Data;
            type == "header_image"
              ? setScrImageHeader(base64Data)
              : setSrcMediaFile({
                  type: file.type,
                });
            setIsMediaRemoveAndDownload(true);
          } else {
            console.error("Upload image index is out of bounds");
            return prevState;
          }

          return {
            ...prevState,
            uploadImage: newUploadImage,
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to normalize base64 MIME types for audio and video
  const normalizeBase64MimeType = (base64String, fileType) => {
    if (
      fileType === "audio/x-m4a" &&
      base64String.startsWith("data:audio/x-m4a")
    ) {
      return base64String.replace("data:audio/x-m4a", "data:audio/m4a");
    }
    if (
      fileType === "video/x-m4a" &&
      base64String.startsWith("data:video/x-m4a")
    ) {
      return base64String.replace("data:video/x-m4a", "data:video/m4a");
    }
    return base64String;
  };

  // handle media download
  const handleDownloadMediaClick = async (mediaType, mediaFile) => {
    if (mediaType === "Image") {
      try {
        setMediaImageLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData(academics, lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setMediaImageLoadingData(false);
      }
    } else {
      try {
        setMediaFileLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData(academics, lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setMediaFileLoadingData(false);
      }
    }
  };

  // handle media remove
  const handleRemoveMediaClick = (type) => {
    if (type === "mediaFiles") {
      setSrcMediaFile("");
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          media: {
            ...prevState.inputData.media,
            url: "",
          },
        },
      }));
    } else {
      setScrImageHeader("");
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          header_image: "",
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (authData && authData?.type == 1) {
      if (!selectedTenant) {
        setTenantError(true);
        return toast.warning("Please select a tenant");
      } else {
        setTenantError(false);
      }
    }

    !selectedMedia ? setMediaError(true) : setMediaError(false);

    const isAudioOrVideo =
      formData.inputData.media.type === "Audio" ||
      formData.inputData.media.type === "Video";

    if (isAudioOrVideo && formData.inputData.duration === "00:00:00") {
      setDurationError(true);
      return;
    } else {
      setDurationError(false);
    }

    if (!formData.inputData.title || !formData.inputData.speaker_name || !formData.inputData.released_date) {
      return toast.warning("All fields are required");
    }

    try {
      setLoadingData(true);
      const filteredUploadImage = formData.uploadImage.filter(
        (image) => image.imagePath !== ""
      );
      var payload = {
        modelName: "academic_channel_posts",
        relation: [
          {
            modelName: "academic_channel_post_group_linkings",
          },
          {
            modelName: "academic_channel_post_audience_linkings",
          },
        ],

        inputData: {
          ...formData.inputData,
          setting: JSON.stringify(formData.inputData.setting),
        },
        uploadImage: filteredUploadImage,
      };

      if (authData && authData?.type == 2) {
        payload.inputData.tenant_id = authData?.tenant_id;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = id
        ? await fetchData("updaterelationaldata", payload, academics)
        : await fetchData("createAndUpdateMaster", payload, academics);

      if (responseData.code == 1) {
        const payload1 = {
          modelName: "academic_channel_post_associations",
          inputData: formChannelModuleData.inputData,
        };

        const payload2 = {
          modelName: "academic_channel_module_associations",
          inputData: {
            ...formChannelModuleData.inputData,
            post_id: responseData.data.id,
          },
        };

        let payload = payload1;
        await fetchData("createAndUpdateMaster", payload, academics);

        payload = payload2;
        await fetchData("createAndUpdateMaster", payload, academics);

        toast.success("Manage Post saved successfully!");
        navigate("/academic-post-list");
      } else {
        toast.error("Error while saving Manage Post! ");
        navigate("/academic-post-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // const handleKeyDown = (e) => {
  //   const { key, target } = e;
  //   const value = target.value;

  //   // Prevent deleting colons
  //   if (["Backspace", "Delete"].includes(key) && value.includes(":")) {
  //     e.preventDefault();
  //   }

  //   // Restrict input to digits only, except for arrow keys and other navigation keys
  //   if (
  //     !/[0-9]/.test(key) &&
  //     !["ArrowLeft", "ArrowRight", "Tab", "Home", "End"].includes(key)
  //   ) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const selectionStart = target.selectionStart;

    // Prevent deleting or editing colons
    if (
      ["Backspace", "Delete"].includes(key) &&
      (selectionStart === 2 || selectionStart === 5)
    ) {
      e.preventDefault();
      return;
    }

    // Restrict input to digits only, except for arrow keys and navigation
    if (
      !/[0-9]/.test(key) &&
      !["ArrowLeft", "ArrowRight", "Tab", "Home", "End"].includes(key)
    ) {
      e.preventDefault();
    }
  };

  const handleTextChange1 = (e) => {
    const input = e.target;
    let value = input.value;

    // Enforce HH:mm:ss format (prevent adding/removing colons)
    let formattedValue = value
      .replace(/[^0-9]/g, "") // Remove non-numeric characters
      .substring(0, 6); // Limit to 6 characters (HHmmss)

    // Reinsert colons at appropriate positions
    if (formattedValue.length >= 2) {
      formattedValue = `${formattedValue.slice(0, 2)}:${formattedValue.slice(
        2
      )}`;
    }
    if (formattedValue.length >= 5) {
      formattedValue = `${formattedValue.slice(0, 5)}:${formattedValue.slice(
        5
      )}`;
    }

    // Update only if valid
    if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(formattedValue)) {
      setFormData({
        ...formData,
        inputData: {
          ...formData.inputData,
          duration: formattedValue,
        },
      });
    }

    const isAudioOrVideo =
      formData.inputData.media.type === "Audio" ||
      formData.inputData.media.type === "Video";
    if (isAudioOrVideo && formattedValue === "00:00:00") {
      setDurationError(true);
    } else {
      setDurationError(false);
    }
  };

  const checkMimeType = (type, types) => {
    return types.includes(type);
  };

  const checkExtension = (url, extensions) => {
    return extensions.some((ext) => url.endsWith(ext));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC POST"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      onSubmit={handleSubmit}>
                      <>
                        <Row>
                          {authData && authData?.type == 1 && (
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Tenant <Asterisk />
                                </Label>
                                <Select
                                  value={selectedTenant}
                                  onChange={handleSelectTenantChange}
                                  options={tenantlist}
                                  classNamePrefix="select2-selection"
                                  noOptionsMessage={() => "no data found.."}
                                  maxMenuHeight={200}
                                  isClearable
                                />
                                {tenantError && (
                                  <span>
                                    <p
                                      style={{
                                        fontSize: "11.5px",
                                        color: "red",
                                      }}>
                                      Select Tenant
                                    </p>
                                  </span>
                                )}
                              </div>
                            </Col>
                          )}

                          {!id ? (
                            <>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Channels</Label>
                                  <Select
                                    value={selectedChannel}
                                    onChange={handleSelectChannelChange}
                                    options={channelList}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    isClearable
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Modules</Label>
                                  <Select
                                    value={selectedModule}
                                    onChange={handleSelectModuleChange}
                                    options={moduleList}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    isClearable
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">Channels</Label>
                                  <AvField
                                    name="channel"
                                    placeholder="Channel"
                                    type="text"
                                    errorMessage="Enter Channel"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={channelText}
                                    disabled={true}
                                    readOnly={true}
                                    style={{
                                      backgroundColor: "lightgray",
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">Modules</Label>
                                  <AvField
                                    name="module"
                                    placeholder="Module"
                                    type="text"
                                    errorMessage="Enter Module"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={moduleText}
                                    disabled={true}
                                    readOnly={true}
                                    style={{
                                      backgroundColor: "lightgray",
                                    }}
                                  />
                                </div>
                              </Col>
                            </>
                          )}

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Post Title
                                <Asterisk />
                              </Label>
                              <AvField
                                name="title"
                                placeholder="Post Title"
                                type="text"
                                errorMessage="Enter Post Title"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                id="validationCustom01"
                                value={formData.inputData.title}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Select Group Id
                              </Label>
                              <Select
                                isMulti={true}
                                value={selectedGroup}
                                onChange={handleSelectGroupChange}
                                options={selectedGroupOption}
                                classNamePrefix="select1-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Post Sub Title
                              </Label>
                              <AvField
                                name="subtitle"
                                placeholder="Post Sub Title"
                                type="text"
                                errorMessage="Enter Post Sub Title"
                                className="form-control"
                                // validate={{
                                //   required: { value: true },
                                // }}
                                id="validationCustom01"
                                value={formData.inputData.subtitle}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Select Audiance Id
                              </Label>
                              <Select
                                isMulti={true}
                                value={selectedAudiance}
                                onChange={handleSelectAudienceChange}
                                options={selectedAudianceOption}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Speaker Name <Asterisk />
                              </Label>
                              <AvField
                                name="speaker_name"
                                placeholder="Speaker Name"
                                type="text"
                                errorMessage="Enter Speaker Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.speaker_name}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Sequence</Label>
                              <AvField
                                name="Sequence"
                                placeholder="Sequence"
                                type="text"
                                errorMessage="Enter Sequence"
                                className="form-control"
                                value={
                                  formData.inputData &&
                                  formData.inputData
                                    .academic_channel_module_associations &&
                                  formData.inputData
                                    .academic_channel_module_associations
                                    .length > 0
                                    ? formData.inputData
                                        .academic_channel_module_associations[0]
                                        .sequence
                                    : 0
                                }
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Keyword</Label>
                              <Select
                                isMulti={true}
                                value={selectedKeyword}
                                onChange={handleSelectKeywordChange}
                                options={selectedKeywordOption}
                                classNamePrefix="select3-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Released Date
                                <Asterisk />
                              </Label>
                              <AvField
                                name="released_date"
                                placeholder="Released Date"
                                type="date"
                                errorMessage="Enter Released Date Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.released_date}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Transcript</Label>
                              <AvField
                                name="transcript"
                                placeholder="Transcript"
                                type="text"
                                errorMessage="Enter Transcript"
                                className="form-control"
                                onChange={handleTextChange}
                                value={formData.inputData.transcript}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Version </Label>
                              <AvField
                                name="version"
                                placeholder="Version"
                                type="text"
                                errorMessage="Enter Version"
                                className="form-control"
                                onChange={handleTextChange}
                                value={formData.inputData.version}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Media Type <Asterisk />
                              </Label>
                              <Select
                                value={selectedMedia}
                                onChange={handleSelecMediatTypeChange}
                                options={mediaList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {mediaError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Media Type
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Thumbnail Picture Link
                              </Label>
                              <AvField
                                name="thumbnail_picture_link"
                                placeholder="Thumbnail Picture Link"
                                type="text"
                                errorMessage="Enter Thumbnail Picture Link"
                                className="form-control"
                                onChange={handleTextChange}
                                value={
                                  formData.inputData.thumbnail_picture_link
                                }
                              />
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {" "}
                                Duration{" "}
                                {selectedMedia?.value === "Audio" ||
                                selectedMedia?.value === "Video" ? (
                                  <Asterisk />
                                ) : null}
                              </Label>
                              <AvField
                                name="duration"
                                placeholder="Duration"
                                type="text"
                                errorMessage={"Enter Duration"}
                                className="form-control"
                                onChange={handleTextChange1}
                                onKeyDown={handleKeyDown}
                                value={formData.inputData.duration}
                                maxLength="8"
                              />
                              {durationError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                      marginTop: "-10px",
                                    }}>
                                    Duration is required and cannot be 00:00:00
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Row>
                            <Col md={6}>
                              <Label
                                className="form-label"
                                htmlFor="mediaFileUpload">
                                Media File <Asterisk />
                              </Label>

                              <div className="input-group">
                                <input
                                  type="file"
                                  className="form-control"
                                  id="mediaFileUpload"
                                  ref={inputRef}
                                  accept={
                                    selectedMedia?.value === "Audio"
                                      ? "audio/*"
                                      : selectedMedia?.value === "Video"
                                      ? "video/*"
                                      : selectedMedia?.value === "Document"
                                      ? ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                      : "*/*"
                                  }
                                  onChange={(e) =>
                                    handleFileChange(e, "media.url")
                                  }
                                />
                              </div>

                              {scrMediaFile && (
                                <Col md="6" className="mt-1">
                                  <div className="mb-3">
                                    <div style={{ display: "flex" }}>
                                      {scrMediaFile?.mediaType === "Document" ||
                                      scrMediaFile?.mediaType === "Audio" ||
                                      scrMediaFile?.mediaType === "Video" ? (
                                        <>
                                          {scrMediaFile.mediaType ===
                                            "Document" &&
                                            checkExtension(
                                              scrMediaFile.mediaURL,
                                              documentExtensions
                                            ) && (
                                              <div>
                                                <img
                                                  src={pdfIMG}
                                                  alt="media document"
                                                  width="100px"
                                                  height="100px"
                                                />
                                              </div>
                                            )}

                                          {scrMediaFile.mediaType === "Audio" &&
                                            checkExtension(
                                              scrMediaFile.mediaURL,
                                              audioExtensions
                                            ) && (
                                              <div>
                                                <img
                                                  src={audioIMG}
                                                  alt="media audio"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </div>
                                            )}

                                          {scrMediaFile.mediaType === "Video" &&
                                            checkExtension(
                                              scrMediaFile.mediaURL,
                                              videoExtensions
                                            ) && (
                                              <div>
                                                <img
                                                  src={videoIMG}
                                                  alt="media video"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          {scrMediaFile &&
                                            (scrMediaFile.mediaType ===
                                              "Document" ||
                                              scrMediaFile.mediaType ===
                                                "Audio" ||
                                              scrMediaFile.mediaType ===
                                                "Video") &&
                                            (checkExtension(
                                              scrMediaFile.mediaURL,
                                              documentExtensions
                                            ) ||
                                              checkExtension(
                                                scrMediaFile.mediaURL,
                                                audioExtensions
                                              ) ||
                                              checkExtension(
                                                scrMediaFile.mediaURL,
                                                videoExtensions
                                              )) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  marginLeft: "10px",
                                                }}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "15px",
                                                  }}>
                                                  {loadingMediaFileData ? (
                                                    <LoaderDownloadMedia />
                                                  ) : (
                                                    <img
                                                      src={downloadIcon}
                                                      alt="download"
                                                      width="30px"
                                                      height="30px"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleDownloadMediaClick(
                                                          scrMediaFile.mediaType.toLowerCase(),
                                                          scrMediaFile.mediaURL
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  <img
                                                    src={removeIcon}
                                                    alt="remove"
                                                    width="30px"
                                                    height="30px"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleRemoveMediaClick(
                                                        "mediaFiles"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {checkMimeType(
                                            scrMediaFile.type,
                                            documentTypes
                                          ) ? (
                                            <img
                                              src={pdfIMG}
                                              alt="media pdf"
                                              width="100px"
                                              height="100px"
                                              style={{ cursor: "pointer" }}
                                            />
                                          ) : checkMimeType(
                                              scrMediaFile.type,
                                              audioTypes
                                            ) ? (
                                            <img
                                              src={audioIMG}
                                              alt="media audio"
                                              width="120px"
                                              height="100px"
                                              style={{
                                                border: ".5px solid gray",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          ) : checkMimeType(
                                              scrMediaFile.type,
                                              videoTypes
                                            ) ? (
                                            <img
                                              src={videoIMG}
                                              alt="media video"
                                              width="120px"
                                              height="100px"
                                              style={{
                                                border: ".5px solid gray",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          ) : null}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Col>
                            <Col md={6}>
                              <Label
                                className="form-label"
                                htmlFor="headerImageUpload">
                                Header Image
                              </Label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  className="form-control"
                                  id="headerImageUpload"
                                  onChange={(e) =>
                                    handleFileChange(e, "header_image")
                                  }
                                />
                              </div>
                              {scrImageHeader && (
                                <Col md="6" className="mt-1">
                                  <div className="mb-3 d-flex">
                                    <div>
                                      <img
                                        src={scrImageHeader}
                                        alt="channel image"
                                        width="120px"
                                        height="100px"
                                        style={{
                                          border: ".5px solid gray",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </div>
                                    {!isMediaRemoveAndDownload && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginLeft: "10px",
                                        }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "15px",
                                          }}>
                                          {loadingMediaImageData ? (
                                            <LoaderDownloadMedia />
                                          ) : (
                                            <img
                                              src={downloadIcon}
                                              alt="download"
                                              width="30px"
                                              height="30px"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleDownloadMediaClick(
                                                  "Image",
                                                  scrImageHeader
                                                )
                                              }
                                            />
                                          )}
                                          <img
                                            src={removeIcon}
                                            alt="remove"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleRemoveMediaClick(
                                                "headerImage"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg="4">
                              <div
                                style={{ display: "inline-flex", gap: "14px" }}>
                                <div className="form-check mb-3">
                                  <input
                                    name="shareable"
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formData.inputData.shareable}
                                    id="invalid_Check"
                                    checked={formData.inputData.shareable}
                                    onChange={handleTextChange}
                                    required=""
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="invalid_Check">
                                    Is Shareable
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formData.inputData.is_active}
                                    id="invalid_Check"
                                    name="is_active"
                                    checked={formData.inputData.is_active}
                                    onChange={handleTextChange}
                                    required=""
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="invalid_Check">
                                    Is Active
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className="waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>

                        <Button
                          color="primary"
                          className="waves-effect waves-light me-1"
                          onClick={() => navigate("/academic-post-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicPostCreate;
