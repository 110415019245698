import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Spinner,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { dropdownData, fetchData, user } from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Asterisk from "../../Asterisk/Asterisk";


const UserAccess = () => {
  const { id } = useParams();
  let navigate=useNavigate()
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [showMasters, setShowMasters] = useState(true);
  const [expandedParent, setExpandedParent] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [data, setData] = useState([]);
  const [checkBoxArryData, setCheckBoxArryData] = useState([]);
  const [checkUpdateId, setCheckUpdateId] = useState([]);
  const [loading, setLoading] = useState(false);

  const breadcrumbItems = [];

  useEffect(() => {
    fetchUserMasterData(id);
  }, []);

  const fetchUserMasterData = async (id) => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      let payload = {
        modelName: "cms_user_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID[0].type === 2) {
        payload.whereCondition.type = 2;
        payload.whereCondition.tenant_id = authID[0].tenant_id;
      }

      let responseData = await fetchData("getMasterList", payload, user);

      if (responseData) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "first_name",
          "user_access"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value) {
              setSelectedUser(element);
              setSelectedUserType(element.type);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMenuMasterData = async () => {
    try {
      setLoading(true);
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      let payload = {
        modelName: "menu_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID[0].type === 2 || selectedUserType === 2) {
        payload.whereCondition.is_visible_to_tenant = 1;
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData) {
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUserAccessData = async (userId) => {
    try {
      const payload = {
        modelName: "access_controls",
        whereCondition: {
          user_id: userId,
          access: 1,
        },
        pagination: {
          page: 1,
          pageSize: 1000000,
        },
      };
      let responseData = await fetchData("getMasterList", payload, user);
    

      setCheckUpdateId(responseData.data);
      if (responseData) {
        const accessData = responseData.data.reduce((acc, item) => {
          acc[item.menu_id] = item.access === 1;
          return acc;
        }, {});

        setCheckboxStates(accessData);
        setCheckBoxArryData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching user access data:", error);
    }
  };

  const handleSelectChange = (selectedUser) => {
    setData([]);
    setSelectedUser(selectedUser ?selectedUser:"");
    setSelectedUserType(selectedUser ?selectedUser?.type:"");
  };

  const handleToggleParent = (parentId) => {
    if (expandedParent === parentId) {
      setExpandedParent(null);
    } else {
      setExpandedParent(parentId);
    }
  };

  const handelShowMasters = () => {
    if (!selectedUser) {
      return toast.error("No user selected");
    } else {
      fetchMenuMasterData();
      getUserAccessData(selectedUser.value);
      setShowMasters(false);
    }
  };

  const handleCheckboxChange = (childId, isChecked) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [childId]: isChecked,
    }));

    setCheckBoxArryData((prevState) => {
      const existingIndex = prevState.findIndex(
        (item) => item.menu_id === childId
      );
      if (existingIndex > -1) {
        const updatedData = [...prevState];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          access: isChecked ? 1 : 0,
        };
        return updatedData;
      } else {
        const existingData = checkUpdateId.find(
          (item) => item.menu_id === childId
        );
        const newCheckboxData = {
          id: existingData ? existingData.id : null,
          user_id: selectedUser?.value,
          menu_id: childId,
          access: isChecked ? 1 : 0,
          is_active: 1,
        };
        return [...prevState, newCheckboxData];
      }
    });
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        modelName: "access_controls",
        inputData: {
          multipleData: checkBoxArryData,
        },
        pagination: {
          page: 1,
          pageSize: 1000000,
        },
      };

      let res = await fetchData("createAndUpdateMaster", payload, user);

      if (res.code === 1) {
        setData([]);
        setCheckBoxArryData([]);
        setCheckboxStates({});
        setExpandedParent(null);
        setCheckUpdateId([]);
        setSelectedUser(null);
        setShowMasters(true);
        toast.success("Access controls updated successfully");
         window.location.reload();
    
      } else {
        toast.error("Failed to update access controls");
      }
    } catch (error) {
      console.error("Error updating user access data:", error);
    }

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="USER ACCESS" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row className="justify-content-center ">
                      <Col md={4}>
                        <Label className="form-label">
                          User
                          <Asterisk />
                        </Label>
                        <Select
                          value={selectedUser}
                          onChange={handleSelectChange}
                          options={tenantlist}
                          classNamePrefix="select2-selection"
                          noOptionsMessage={() => "no data found.."}
                          maxMenuHeight={200}
                          isClearable
                        />
                      </Col>

                      <Col style={{ marginTop: "28px", paddingBottom: "20px" }}>
                        <Button
                          onClick={handelShowMasters}
                          color="primary"
                          type="button"
                          className="waves-effect waves-light me-1">
                          Go
                        </Button>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        height: "55vh",
                        overflow: "auto",
                        // scrollbarWidth: "none",
                        // msOverflowStyle: "none",
                      }}>
                      {" "}
                      {loading ? (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        !showMasters &&
                        data
                          .filter((item) => item.is_child === 0)
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((parent) => (
                            <React.Fragment key={parent.id}>
                              <Row style={{ paddingBlock: "12px" }}>
                                <Col xl="12" className="mt-1">
                                  <div
                                    style={{
                                      backgroundColor: "#F4F6FA", //#70788D //#4C556C //D1D6E3
                                      paddingBlock: "6px",
                                      borderTopLeftRadius: "4px",
                                      borderTopRightRadius: "4px",
                                    }}>
                                    <h5
                                      style={{
                                        margin: 0,
                                        alignItems: "center",
                                        display: "flex",
                                        width: "100%",
                                        paddingLeft: 10,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleToggleParent(parent.id)
                                      }>
                                      {parent.menu_name}
                                      <i
                                        className={`mdi mdi-chevron-${
                                          expandedParent === parent.id
                                            ? "up"
                                            : "down"
                                        } mt-1`}
                                        style={{
                                          fontSize: "22px",
                                          marginLeft: "3px",
                                        }}
                                      />
                                    </h5>
                                  </div>
                                  {expandedParent === parent.id && (
                                    <div>
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Menu</th>
                                            <th>Access</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data
                                            .filter(
                                              (child) =>
                                                child.parent_id === parent.id
                                            )
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((child) => (
                                              <tr key={child.id}>
                                                <td>{child.menu_name}</td>
                                                <td>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                      checkboxStates[child.id]
                                                    }
                                                    onChange={(e) =>
                                                      handleCheckboxChange(
                                                        child.id,
                                                        e.target.checked
                                                      )
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          ))
                      )}
                    </Row>

                    {data.length > 0 && (
                      <Row className="mt-4">
                        <Col
                          style={{
                            paddingTop: "10px",
                            borderTop: "1px solid #ced4da",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}>
                          <Button color="primary" onClick={handleSubmit}>
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserAccess;
