import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import { useAuth } from "../../../services/useAuth";

const ManageAudienceIdCreate = ({}) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  const authData = useAuth();
  let { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    tenant_id: "",
    audience_name: "",
    llc_audience_id: "",
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, [authData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "audience_id_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          getTenantDropdownData(responseData.data[0].tenant_id);
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      tenant_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (authData && authData?.type == 1) {
      if (!selectedTenant) {
        setTenantError(true);
        return toast.warning("Please select a tenant");
      } else {
        setTenantError(false);
      }
    }

    if (!formData.audience_name || !formData.llc_audience_id) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = {
        modelName: "audience_id_masters",
        inputData: formData,
      };

      if (authData && authData?.type == 2) {
        payload.inputData.tenant_id = authData?.tenant_id;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Audience Id saved successfully!");
        navigate("/manage-audience-id-list");
      } else {
        toast.error(responseData.message);
        // navigate("/manage-audience-id-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/manage-audience-id-list"); // Navigate to the desired route
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE MANAGE AUDIENCE ID"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          {authData && authData?.type == 1 && (
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Tanent
                                  <Asterisk />
                                </Label>
                                <Select
                                  value={selectedTenant}
                                  onChange={handleSelectChange}
                                  options={tenantlist}
                                  classNamePrefix="select2-selection"
                                  noOptionsMessage={() => "no data found.."}
                                  maxMenuHeight={200}
                                  isClearable
                                />
                                {tenantError && (
                                  <span>
                                    <p
                                      style={{
                                        fontSize: "11.5px",
                                        color: "red",
                                      }}>
                                      Select Tenant
                                    </p>
                                  </span>
                                )}
                              </div>
                            </Col>
                          )}
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Audience Id Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="audience_name"
                                placeholder="Audience Id name"
                                type="text"
                                errorMessage="Enter Audience Id Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.audience_name}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Audience Id
                                <Asterisk />
                              </Label>
                              <AvField
                                name="llc_audience_id"
                                placeholder="Audience Id"
                                type="number"
                                min="1"
                                errorMessage="Enter Audience Id"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[1-9]\\d*$",
                                    errorMessage:
                                      "Please enter a positive value greater than zero",
                                  },
                                }}
                                value={formData.llc_audience_id}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageAudienceIdCreate;
