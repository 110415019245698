import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types'; // Import PropTypes
import { debounce } from 'lodash'; // Add this import

// Styled Components
const StyledCard = styled(Card)`
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: ${props => {
    if (props.$windowWidth < 576) return '540px';
    if (props.$windowWidth < 768) return '540px';
    if (props.$windowWidth < 992) return '540px';
    return '540px';
  }};
  width: ${props => {
    if (props.$windowWidth < 576) return '100%';
    if (props.$windowWidth < 992) return '100%';
    return '100%';
  }};
  margin: 0 auto;
`;

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h4`
  font-size: ${props => props.$windowWidth < 768 ? '15px' : '16px'};
  font-weight: 600;
  margin-bottom: 10px;
`;

const ChartContainer = styled.div`
  margin: ${props => props.$windowWidth < 992 ? '20px 0' : '30px 0'};
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${props => {
    if (props.$windowWidth < 576) return '300px';
    if (props.$windowWidth < 768) return '300px';
    if (props.$windowWidth < 992) return '400px';
    return '468px';
  }};
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const ToggleButton = styled.button.attrs(props => ({
  'data-active': props['data-active'], // Use data-active instead of isActive
}))`
  padding: 5px 10px;
  border: none; // Remove the border
  border-radius: 20px;
  height: 40px;
  width: 90px;
  background: ${props => props['data-active'] ? '#5664D2' : '#F2F2F7'}; // Active color matches pie chart
  color: ${props => props['data-active'] ? 'white' : 'black'};
  margin-right: ${props => props.$marginRight ? '5px' : '0'};
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 12px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${props => props['data-active'] ? '#4a5bbf' : '#E0E0E0'}; // Darker shade for hover
  }
`;

const SkeletonWrapper = styled.div`
  padding: ${props => props.$windowWidth < 576 ? '10px' : '15px'};
  height: ${props => {
    if (props.$windowWidth < 576) return '300px';
    if (props.$windowWidth < 768) return '350px';
    if (props.$windowWidth < 992) return '400px';
    return '420px';
  }};
  display: flex;
  flex-direction: column;
`;

const SkeletonButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
`;

const StatBox = styled.div`
    border-radius: 10px;
    padding: ${props => props.$windowWidth < 576 ? '8px' : '10px'};
    text-align: center;
    height: 100%;
    background: ${props => props.$isAndroid ? '#E8F5E9' : '#E6F3FF'};
`;

const StatValue = styled.h3`
    color: ${props => props.$isAndroid ? '#3DDC84' : '#007AFF'};
    font-size: ${props => props.$windowWidth < 576 ? '16px' : '18px'};
    font-weight: 700;
    margin-bottom: 5px;
`;

const StatLabel = styled.p`
    color: #8E8E93;
    margin-bottom: 0;
    font-size: ${props => props.$windowWidth < 576 ? '12px' : '14px'};
`;

const UserAnalytics = ({ android, ios, loading }) => {
  const [showPercentage, setShowPercentage] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 });
  const chartRef = useRef(null);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel();
    };
  }, [handleResize]);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        const { offsetWidth, offsetHeight } = chartRef.current;
        setChartDimensions({
          width: offsetWidth,
          height: offsetHeight
        });
      }
    };

    updateDimensions();
    const debouncedUpdate = debounce(updateDimensions, 100);
    window.addEventListener('resize', debouncedUpdate);

    return () => {
      window.removeEventListener('resize', debouncedUpdate);
      debouncedUpdate.cancel();
    };
  }, [windowWidth]);

  const chartData = useMemo(() => {
    if (!loading && android !== undefined && ios !== undefined) {
      const totalUsers = android + ios;
      return {
        series: [android, ios],
        labels: ["Android", "iOS"],
        percentages: [
          Math.round((android / totalUsers) * 100),
          Math.round((ios / totalUsers) * 100)
        ]
      };
    }
    return { series: [], labels: [], percentages: [] };
  }, [android, ios, loading]);

  const options = useMemo(() => ({
    chart: {
      background: 'transparent',
    },
    labels: chartData.labels,
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total Users',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString();
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    colors: ['#1cbb8c', '#5664D2'],
    stroke: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  }), [chartData.labels]);

  const renderValue = (value, percentage) => {
    return showPercentage ? `${percentage}%` : value.toLocaleString();
  };

  return (
    <StyledCard $windowWidth={windowWidth}>
      <StyledCardBody>
        {loading ? (
          <SkeletonWrapper $windowWidth={windowWidth}>
            <Skeleton height={25} width="100%" style={{ marginBottom: '10px' }} />
            <div style={{ flex: 1, marginBottom: '15px' }}>
              <Skeleton height="100%" width="100%" />
            </div>
            <SkeletonButtonGroup>
              <Skeleton height={35} width={80} borderRadius={20} />
              <Skeleton height={35} width={80} borderRadius={20} />
            </SkeletonButtonGroup>
            <Row>
              <Col xs={6}>
                <Skeleton height={70} borderRadius={10} />
              </Col>
              <Col xs={6}>
                <Skeleton height={70} borderRadius={10} />
              </Col>
            </Row>
          </SkeletonWrapper>
        ) : (
          <>
            <Title $windowWidth={windowWidth}>User Analytics (All Time)</Title>
            <ChartContainer 
              ref={chartRef}
              $windowWidth={windowWidth}
            >
              {chartData.series.length > 0 && 
               chartDimensions.width > 0 && 
               chartDimensions.height > 0 && (
                <ReactApexChart
                  options={{
                    ...options,
                    chart: {
                      ...options.chart,
                      width: chartDimensions.width,
                      height: chartDimensions.height,
                    }
                  }}
                  series={chartData.series}
                  type="donut"
                  width={chartDimensions.width}
                  height={chartDimensions.height}
                />
              )}
            </ChartContainer>
            <ButtonGroup>
              <ToggleButton
                data-active={showPercentage}
                $marginRight={true}
                onClick={() => setShowPercentage(true)}
              >
                Percentage
              </ToggleButton>
              <ToggleButton
                data-active={!showPercentage}
                $marginRight={false}
                onClick={() => setShowPercentage(false)}
              >
                Absolute
              </ToggleButton>
            </ButtonGroup>
            <Row>
              {chartData.labels.map((label, index) => (
                <Col xs={6} key={label}>
                  <StatBox 
                    $windowWidth={windowWidth} 
                    $isAndroid={index === 0}
                  >
                    <StatValue 
                      $windowWidth={windowWidth} 
                      $isAndroid={index === 0}
                    >
                      {chartData.series[index] > 0 ? renderValue(chartData.series[index], chartData.percentages[index]) : '0'}
                    </StatValue>
                    <StatLabel $windowWidth={windowWidth}>{label}</StatLabel>
                  </StatBox>
                </Col>
              ))}
            </Row>
          </>
        )}
      </StyledCardBody>
    </StyledCard>
  );
};

UserAnalytics.propTypes = {
  android: PropTypes.number.isRequired,
  ios: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserAnalytics;
