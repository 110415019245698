import React, { useState, useMemo, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import PropTypes from 'prop-types'; // Import PropTypes

const HeaderTitle = styled.h2`
    margin: 0;
    font-size: ${props => {
        if (props.$windowWidth < 576) return '12px';     // Mobile
        if (props.$windowWidth < 768) return '15px';     // Tablet
        if (props.$windowWidth < 992) return '16px';     // Small laptop
        return '16px';                                  // Desktop
    }};
    color: #333;
    font-weight: 600;
`;

const ChartContainer = styled.div`
    height: ${props => props.$windowWidth < 576 ? '250px' : '300px'};
    margin-bottom: ${props => props.$windowWidth < 576 ? '10px' : '15px'};
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: ${props => props.$windowWidth < 576 ? '5px' : '10px'};
    gap: 3px;
`;

const StyledButton = styled.button`
    flex: 1;
    min-width: ${props => props.$windowWidth < 576 ? '60px' : '70px'};
    max-width: ${props => props.$windowWidth < 576 ? '80px' : '90px'};
    padding: ${props => props.$windowWidth < 576 ? '5px 0' : '7px 0'};
    font-size: ${props => props.$windowWidth < 576 ? '11px' : '12px'};
    background-color: ${props => props.$isSelected ? '#5664D2' : '#F8F9FA'};
    color: ${props => props.$isSelected ? 'white' : 'black'};
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 2px;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${props => props.$isSelected ? '#5664D2' : '#e9ecef'};
    }
`;

const MonthDisplay = styled.div`
    text-align: center;
    font-size: ${props => props.$windowWidth < 576 ? '12px' : '15px'};
    height: 20px;
    overflow: hidden;
`;

const CardWrapper = styled.div`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: ${props => props.$windowWidth < 576 ? '10px' : '20px'};
    width: 100%;
    max-width: none;
    margin: 5px auto;
    height: ${props => {
        if (props.$windowWidth < 576) return '380px';    // Mobile
        if (props.$windowWidth < 768) return '400px';    // Tablet
        if (props.$windowWidth < 992) return '418px';    // Small laptop
        return '418px';                                 // Desktop
    }};
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.$windowWidth < 576 ? '30px' : '30px'};
    flex-wrap: wrap;
    padding: ${props => props.$windowWidth < 576 ? '0 10px' : ' 1px'};
`;

const SkeletonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: ${props => props.$windowWidth < 576 ? '15px' : '20px'};
`;

const SkeletonButtonGroup = styled(ButtonGroup)`
    display: flex;
    gap: 10px;
    margin-top: 15px;
`;
const PlaceholderMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: #888;
    margin-top: 150px;
`;


const ActiveUserAnalytics = ({ graphData, loading }) => {
    const [timeframe, setTimeframe] = useState("monthly");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const activityGraphData = graphData;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Memoized graph data and labels
    const graphDataMemo = useMemo(() => {
        if (!activityGraphData) return [];
        switch (timeframe) {
            case "weekly":
                return activityGraphData.weeklyActivityData?.map(item => item.count) || [];
            case "monthly":
                return activityGraphData.monthlyActivityData?.map(item => item.count) || [];
            case "yearly":
                return activityGraphData.yearlyActivityData?.map(item => item.count) || [];
            default:
                return [];
        }
    }, [activityGraphData, timeframe]);

    const labelsMemo = useMemo(() => {
        if (!activityGraphData) return [];
        switch (timeframe) {
            case "weekly":
                return activityGraphData.weeklyActivityData?.map(item => item.date) || [];
            case "monthly":
                // const currentMonth = new Date().getMonth();
                // setSelectedMonth(months[currentMonth]);
                return activityGraphData.monthlyActivityData?.map(item => new Date(item.date).getDate()) || [];
                // return [months[currentMonth], ...activityGraphData.monthlyActivityData?.map(item => item.date) || []];
            case "yearly":
                return months;
            default:
                return [];
        }
    }, [activityGraphData, timeframe]);

    const series = useMemo(() => {
        if (!graphDataMemo.length) return [];
        return [{
            name: 'User Activity',
            type: 'column',
            data: graphDataMemo.map(value => 
                typeof value === 'number' && !isNaN(value) ? value : 0
            ),
        }];
    }, [graphDataMemo]);

    const options = useMemo(() => ({
        chart: { 
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: false
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            redrawOnParentResize: false,
            redrawOnWindowResize: true
        },
        stroke: { width: [0, 3], curve: 'smooth' },
        plotOptions: { 
            bar: { 
                horizontal: false, 
                columnWidth: '20%',
                distributed: false,
            }
        },
        dataLabels: { 
            enabled: false,
            enabledOnSeries: undefined
        },
        legend: { show: false },
        colors: ['#5664d2'],
        labels: labelsMemo,
        xaxis: {
            categories: labelsMemo,
            tickPlacement: 'on',
            labels: {
                show: true,
                style: { fontSize: '12px' },
                rotate: -45,
                formatter: (value) => (timeframe === "monthly" && window.innerWidth < 600 ? ' ' : value),
            },
         
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            }
        },
        yaxis: {
          
        
        
        },
        grid: {
            padding: {
                top: 0,
                right: 10,
                bottom: 0,
                left: 10
            }
        }
    }), [labelsMemo, graphDataMemo, timeframe]);

    useEffect(() => {
        return () => {
            if (window.ApexCharts) {
                window.ApexCharts.exec('activeUserChart', 'destroy');
            }
        };
    }, []);

    // Memoize chart dimensions
    const chartDimensions = useMemo(() => ({
        height: windowWidth < 576 ? '200px' : '250px'
    }), [windowWidth]);

    // Memoize container styles
    const containerStyles = useMemo(() => ({
        height: windowWidth < 576 ? '380px' : 
               windowWidth < 768 ? '400px' : 
               windowWidth < 992 ? '420px' : '435px'
    }), [windowWidth]);

    return (
<CardWrapper $windowWidth={windowWidth}>
    {loading ? (
        <SkeletonContainer>
            <Skeleton height={30} width="100%" style={{ marginBottom: '15px' }} />
            <Skeleton height={20} width="100%" style={{ marginBottom: '10px' }} />
            <SkeletonButtonGroup>
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
            </SkeletonButtonGroup>
            <Skeleton height={170} width="100%" style={{ marginTop: '15px' }} />
        </SkeletonContainer>
    ) : (
        <>
            <HeaderContainer $windowWidth={windowWidth}>
                <HeaderTitle $windowWidth={windowWidth}>
                    Active User Analytics
                </HeaderTitle>
                {timeframe === "monthly" && (
                    <MonthDisplay>{selectedMonth}</MonthDisplay>
                )}
                {timeframe === "yearly" && (
                    <MonthDisplay>{new Date().getFullYear()}</MonthDisplay>
                )}
                <ButtonGroup>
                    {['weekly', 'monthly', 'yearly'].map((frame) => (
                        <StyledButton
                            key={frame}
                            $isSelected={timeframe === frame}
                            onClick={() => setTimeframe(frame)}
                        >
                            {frame.charAt(0).toUpperCase() + frame.slice(1)}
                        </StyledButton>
                    ))}
                </ButtonGroup>
            </HeaderContainer>
            <ChartContainer $windowWidth={windowWidth}>
                {Object.keys(graphData ?? {}).length === 0  ? (
                    <PlaceholderMessage>No data available for the selected timeframe.</PlaceholderMessage>
                ) : (
                    <ReactApexChart 
                        options={options} 
                        series={series} 
                        type="line" 
                        height="100%" 
                        width="100%"
                    />
                )}
            </ChartContainer>
        </>
    )}
</CardWrapper>

    );
};

ActiveUserAnalytics.propTypes = {
    graphData: PropTypes.shape({
        weeklyActivityData: PropTypes.array,
        monthlyActivityData: PropTypes.array,
        yearlyActivityData: PropTypes.array,
    }),
};

export default ActiveUserAnalytics;
