import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, user } from "../../../services/fetchData";
import { useAuth } from "../../../services/useAuth";

const breadcrumbItems = [];

const IBOUserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const authData = useAuth();


  useEffect(() => {
    getData();
  }, [searchKeyword, page, pageSize]);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId");
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      authID = authID[0];
      const payload = {
        modelName: "user_masters",

        relations: [
          {
            module: "temp_tenant_masters",
            moduleas: "temp_tenant_masters",
          },
        ],
        search: {
          field_name: ["name", "username"],
          searchKeyword: searchKeyword,
        },
        whereCondition: {
          type: 1,
        },
        pagination: {
          page: page + 1,
          pageSize: pageSize,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.tenant_id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.tenant_id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload, user);

      setTotalCount(responseData.count);
      responseData.data.forEach((element, index) => {
        element.index = page * pageSize + (index + 1);
      });
      const sortedData = responseData.data.sort((a, b) => b.index - a.index);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/ibo-user-create");
  };

  const nextToPages = () => {
    setPage(page + 1);
  };

  const previousToPages = () => {
    setPage(page - 1);
  };
  const lastToPages = () => {
    setPage(Math.ceil(totalCount / pageSize) - 1);
  };

  const previousFirstToPages = () => {
    setPage(0);
  };

  const handlePageSizeChange = (data) => {    
    setPageSize(data);
  };

  const columns = useMemo(() => {
    if (!authData) return [];
    return [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },

      ...(authData?.type !== 2
        ? [
          {
            Header: "Tenant Name",
            accessor: (row) =>
              row.tenant_masters && row.tenant_masters.tenant_company_name
                ? row.tenant_masters.tenant_company_name
                : "-",
            disableFilters: true,
            filterable: false,
          },
        ]
        : []),
      {
        Header: "Name",
        accessor: (row) => (row.name && row.name ? row.name : "-"),
        disableFilters: true,
        filterable: false,
      },

      // {
      //   Header: "Email",
      //   accessor: (row) => (row.email && row.email ? row.email : "-"),
      //   disableFilters: true,
      //   filterable: false,
      // },
      {
        Header: "Username",
        accessor: (row) => (row.username && row.username ? row.username : "-"),
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Audience ID",
        accessor: (row) =>
          row.login_session_reference_details &&
            row.login_session_reference_details.audienceIDs
            ? row.login_session_reference_details.audienceIDs
            : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },

      {
        Header: "Group ID",
        accessor: (row) =>
          row.login_session_reference_details &&
            row.login_session_reference_details.groupids
            ? row.login_session_reference_details.groupids
            : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },

      // {
      //   Header: "DOB",
      //   accessor: (row) => (row.dob ? row.dob : "-"),
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: ({ value }) => {
      //     if (!value || isNaN(Date.parse(value))) {
      //       return "-"; // Return a placeholder if the value is invalid or not a date
      //     }

      //     const date = new Date(value);
      //     const formattedDate = date.toISOString().split("T")[0]; // yyyy-mm-dd
      //     return formattedDate;
      //   },
      // },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
    ]
  },[authData]
  );

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="IBO User List"
              breadcrumbItems={breadcrumbItems}
            />
            {/* <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create IBO User
            </Button> */}
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
                pageIndexProp={page}
                onPageSizeChange={handlePageSizeChange}
                nextToPage={nextToPages}
                previousToPage={previousToPages}
                totalCount={totalCount}
                lastToPage={lastToPages}
                previousFirstToPage={previousFirstToPages}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IBOUserList;
